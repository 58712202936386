.playlist-preview-list {
  .playlist-fetch-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ant-list {
  &.ant-list-split {
    &.playlist-preview-list {
      display: flex;
      // align-content: center;
      // justify-content: space-between;
      max-width: 100%;
      width: 100%;
      position: relative;
      // left: 50%;

      .playlist-fetch-error {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .ant-spin-container {
        .playlist-card-home-position {
          margin-left: 2rem;
          position: absolute;
        }
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';