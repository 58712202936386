.terms-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .terms-modal-link {
        color: #ef8508;
        border-bottom: 1px solid #ef8508;
        cursor: pointer;
    }

    .ant-checkbox-wrapper {
        padding-left: 0;
        padding-right: 1rem;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';