@import "@styles/mixins.less";

.playlist-selectable-list {
  height: 55vh;
  overflow-y: scroll;
  .custom-scrollbar();

  .ant-spin-nested-loading {
    .ant-spin-container {
      display: block;
    }
  }
  .ant-list-item {
    display: block;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';