.transactions-list {
    width: 85%;
    margin: auto;
    height: 25vh;

    .ant-list-item {
        display: block;
        border-bottom: none;
        padding: 0;
        margin: 2.2rem 0;
    }
    .title,
    .price {
        color: var(--main-layout-text-color);
        font-weight: normal;
        font-size: 2rem;

        &.balance {
            color: var(--main-layout-text-color-2);
        }
    }

    .date {
        color: #a8a8a8;
        font-size: 2rem;
    }

    .content-container {
        display: flex;
        justify-content: space-between;
    }

    .paid,
    .not-paid {
        font-size: 1.4rem;
    }
    .paid {
        color: var(--color-green);
    }
    .not-paid {
        color: var(--color-red);
    }
}

@media only screen and (max-width: 1200px) {
    .transactions-list {
        .date,
        .price,
        .title {
            font-size: 1.6rem;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';