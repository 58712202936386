@import "@styles/mixins.less";

.infobox-prototype {
  position: relative;
  z-index: 25;
  margin: -2rem 0 1rem 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  background-color: #1c3646;

  .infobox-title-container {
    display: flex;
    justify-content: space-between;
    padding: 0.7rem;
    align-items: center;
  }
  .box {
    display: flex;
    transform: translateY(-1rem);
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.3rem;
    line-height: 2;
    padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    background-color: #1c2832;
    text-shadow: 0 0 2px black;
    border-radius: 0.4rem;
    left: 1rem;
    top: -1.2rem;
    border: 1px solid #ffffff38;
    min-width: 12rem;
  }
  .infobox-subtitle {
    position: absolute;
    z-index: 2;

    .box();

    .series-title {
      display: flex;
      // align-items: center;
      background-color: transparent;
      color: #fff;
      min-width: 12rem;

      .ant-image {
        background-color: #f08507;
        width: 3rem;
      }

      span {
        padding-left: 0.5rem;
      }
    }
  }
  .infobox-time {
    margin-top: 3rem;
    margin-left: 1rem;
    .box();
    width: 90%;
  }
  .infobox-btns {
    transform: translateY(2rem);
    margin: 0 0.5rem;
    height: 0;
    visibility: hidden;
    transition: height 0.2s ease-in-out;

    &.active {
      min-height: 4.4rem;
      visibility: visible;
    }
    .ant-btn-prototype {
      span {
        padding: 0.1rem 0.3rem;
      }

      &.btn-sm-white {
        background-color: transparent;
        width: fit-content;
        padding: 0.2rem;
        min-width: 6.5rem;
        min-height: initial;
        border-color: white;
        border-radius: 5px;
        font-size: 1.2rem;
        margin: 0 0.5rem;
        &.with-icon {
          background-image: url("../../../../../assets/icons/share-prototype.svg");
          background-repeat: no-repeat;
          background-size: 1.8rem;
          background-position: 2px;
          padding-left: 1.8rem;
        }
      }
    }
  }

  .infobox-title {
    font-size: 1.7rem;
    justify-self: center;
    padding-top: 1.5rem;
    color: #ffffffbf;
    font-family: sans-serif;
    font-weight: normal;
    text-transform: capitalize;
  }
  .infobox-description {
    font-size: 1.2rem;
    padding: 0 1rem 1rem 1rem;
    color: #c7d0d5;
    display: block;
    line-height: 1.1;
    overflow-y: scroll;
    max-height: 8rem;
    .custom-scrollbar();
  }
  .channel-menu-prototype {
    align-self: flex-start;
    margin-right: 0.5rem;
    position: absolute;
    top: 5px;
    right: 2px;
  }
  .hide {
    display: none;
  }

  .infobox-more-info {
    border-top: 1px solid rgba(238, 238, 238, 0.171);
    padding: 1rem;
    width: 100%;
    animation: fade 0.3s ease-in-out;
    transition: all 0.3 ease-in-out;
    &__title {
      color: white;
      font-size: 1.7rem;
    }

    &__img {
      all: unset;
      object-fit: cover;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 1218px) {
  .infobox-prototype {
    .infobox-btns {
      &.active {
        min-height: 6.7rem;

        &.channels {
          min-height: 4.4rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .infobox-prototype {
    .infobox-btns {
      &.active {
        min-height: 4.4rem;
      }
    }
  }
}
@media only screen and (max-width: 588px) {
  .infobox-prototype {
    .infobox-btns {
      &.active {
        min-height: 6.7rem;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .infobox-prototype {
    .infobox-btns {
      &.active {
        min-height: 10.5rem;
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';