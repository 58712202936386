.favorites-list {
    margin: auto;
    position: relative;
    min-height: 45vh;
    padding: 1.5rem;
    padding-top: 0;

    .ant-spin-container
        .favorites-list-item-container:last-child
        .kebab-menu-modal-container {
        // color: red !important;
        top: -150%;
        // right: 3rem;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';