.report-content-modal-prototype {
  &.ant-modal {
    top: 1rem;
  }
  .ant-modal-title {
    font-size: 2rem;
    font-weight: normal;
    color: white;
  }
  .ant-modal-body {
    border-top: 0;
    padding-top: 1rem;
    .ant-space-item {
      color: white;
    }
    .ant-radio-group {
      .ant-space-vertical {
        gap: 1rem !important;
      }
    }
    .ant-space-item label {
      color: white;
    }
  }
  .ant-modal-content,
  .ant-modal-header {
    background-color: rgba(11, 90, 142);

    .ant-radio-group {
      .ant-radio-wrapper {
        font-size: 1.8rem;
      }
    }

    input {
      color: black;
    }
    .report-reason {
      margin-top: 0.8rem;
      background-color: #fff;
    }

    .report-btn {
      border-radius: 0;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';