@import "@styles/variables.less";

.ant-modal {
  top: 30%;
  max-width: 520px;
  width: auto !important;

  width: initial;
  .choice-logo {
    width: 12rem;
  }
  &.desktop-modal {
    width: 100%;
    overflow: hidden;
    min-height: 82vh;
    &.auth-modal {
      height: 100vh;
      width: 100% !important;
      top: 2%;
      border-radius: 1rem;
      overflow: hidden;
      .ant-modal-content {
        overflow: hidden;
      }
      .ant-modal-body {
        width: 90%;
        margin: auto;
      }
    }
    min-width: initial;
  }

  .ant-modal-title {
    color: @text-color-dark;
    text-align: center;
    font-weight: bold;
    letter-spacing: @letter-spacing-big;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-header,
  .ant-modal-content {
    border-radius: 2.4rem;
  }
  .ant-modal-content {
    margin: auto;
  }
  .ant-modal-body {
    border-top: 2px solid lighten(@modal-border-color, 50%);
    form {
      margin: auto 7.7rem auto 7.7rem;
    }
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-btn {
    display: block;
    margin: 1.8rem auto 1.6rem auto;
  }
}

.ant-modal-prototype {
  height: calc(100% - 50px);
  width: 100% !important;
  min-width: 100%;
  top: 0;
  margin: 0;
  background-color: rgba(12, 89, 141, 0.95);
  color: white;
  overflow: auto;

  .ant-modal-content {
    width: 90%;
    margin: auto;
    background-color: transparent;
    box-shadow: none;
    position: static;
    height: 95vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-modal-header {
    background-color: transparent;
    margin: 100px 10px 20px 10px;
    padding: 0;
  }
  .ant-modal-title {
    text-align: center;
    font-weight: normal;
    font-size: 34px;
    color: white;
    letter-spacing: @letter-spacing-big;
    line-height: 1.1;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-header,
  .ant-modal-content {
    border-radius: 0;
  }
  .ant-modal-body {
    padding: 0;
    margin-top: 1rem;
    background-color: transparent;
    border: none;
    form {
      margin: 0;
    }
  }
  .ant-modal-footer {
    background-color: transparent;
    border-top: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    margin-top: 2rem;

    button {
      border-radius: 1rem;
      margin: 0 !important;
    }
  }
  .ant-btn {
    display: inline-block;
    margin: 0;
  }
  .ant-modal-close {
    margin: 1rem;
  }
  .ant-modal-close-x {
    color: white;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    font-weight: bold;
    font-size: 2.5rem;
  }
}

.modal-basic-prototype {
  &.ant-modal .ant-modal-content {
    border-radius: 0;
    // width: 90%;
    &.desktop-modal {
      top: 8%;
      width: calc(50% - 30px);
    }
  }

  .ant-modal-body {
    padding: 4rem;
    padding-bottom: 0;
  }
  .ant-modal-close {
    height: 3rem;
    width: 3rem;
    padding: 0;
    border: 0.5rem solid #333;
    border-radius: 50%;
    background-color: #333;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    margin: 0.5rem;
  }
  .ant-modal-close-x {
    height: auto;
    width: auto;
    line-height: 1.2;
  }
  .ant-modal-close-icon svg {
    fill: #fff;
  }

  .modal-button {
    background-color: #f08605;
    border: unset;
    color: white;
    padding: 6px 3rem;
    border-radius: 4px;
    font-size: 16px;
  }
}

@media only screen and (max-height: 800px) {
  .ant-modal {
    &.desktop-modal {
      min-height: 90%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal-prototype {
    height: calc(100% - 6.5rem);
  }
}
@media only screen and (max-height: 600px) {
  .ant-modal {
    &.desktop-modal {
      min-height: 80%;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';