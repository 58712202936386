.subscribed-channels-list {
  .ant-list-item {
    border-bottom: none;
    justify-content: normal;
    display: grid;
    grid-template-columns: 9rem auto;
    gap: 1rem;
    margin: 1.5rem 0;
    .image-container {
      img {
        width: 9.6rem;
      }
    }
    .subscribtion-text-container {
      .title {
        color: var(--main-layout-text-color);
        font-weight: normal;
        margin-top: 1rem;
      }
      .sub-text {
        color: #a5b1c6;
      }
    }
  }

  .subscribed-channel-item-prototype {
    display: block;
    .subscribtion-text-container {
      display: grid;
      grid-template-columns: 2fr 3fr;

      .title {
        justify-self: baseline;
      }

      .sub-text {
        text-align: right;
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';