.unsubscribe-tier-modal {
  .text {
    font-size: 1.8rem;
  }
  button.ant-btn {
    margin: 0;
    display: initial;
    &:hover {
      min-width: 20rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';