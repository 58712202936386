@import "@styles/variables.less";

.form-label {
    letter-spacing: @letter-spacing-small;
    margin-top: -3.5rem;
    display: block;
    color: @text-color-dark;
    line-height: 1.2;
    margin-bottom: 4rem;
}

.register-btn-container,
.done-btn-container {
    margin-top: 2rem;
    margin-bottom: 5rem;
    button {
        display: block;
        margin: 0 auto;
        box-shadow: @light-box-shadow;
    }
}

.register-form {
    .re-captcha {
        transform: scale(0.9);
        position: relative;
        left: -2rem;
        div:first-of-type {
            margin: auto;
            margin-bottom: 2rem;

        }
    }
}
.otp-container {
    .resend-text {
        text-align: center;
        color: @text-color-dark;

        .resend-code {
            cursor: pointer;
            font-weight: bold;

            &.disable-resend-code {
                pointer-events: none;
            }
        }
    }
}

.register-form-mobile {
    width: 90%;
    margin: 3rem auto;

    .registration-label-mobile {
        font-size: 2rem;
        color: @text-color-dark;
        display: block;
        font-weight: bold;
        text-align: left;
        padding-left: 0;
        margin: 1.6rem 0;
    }

    .form-label {
        font-size: 1.2rem;
    }
}

.link-login-content-mobile {
    margin-top: 1rem;
    text-align: center;
    color: @text-color-dark;

    & a {
        color: @text-color-dark;
        font-weight: bold;
    }
}

.ant-picker {
    width: 100%;
    background: transparent;
    border: none;
}
.ant-select-selector {
    background: transparent !important;
    border: none !important;
}

@media only screen and (max-width: 992px) {
    .otp-style {
        min-width: 3rem !important;
        max-height: 3rem;
        font-size: 2.4rem !important;
    }

    .register-form {
        .re-captcha {
            transform: scale(0.8);
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';