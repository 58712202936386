@import "@styles/mixins.less";

.following-video-list-item-container {
    width: 95%;
    padding: 1.6rem;
    margin: 0 auto;

    &.following-video-list-item-active {
        background-color: #0f1e39;
    }

    position: relative;
    &:hover {
        background-color: #0f1e39;
        transition: all 0.2s;
    }
    .ant-list-item {
        display: grid;
        grid-template-columns: auto 1.5fr;
        grid-column-gap: 2.8rem;
        align-items: start;
        padding: 0;
        .heading-container {
            display: flex;
        }

        .video-info {
            font-size: 1.2rem;
            color: var(--main-layout-color-1);
        }

        .video-heading-container {
            display: flex;
            margin-bottom: 3.5rem;
        }
        .video-heading {
            font-size: 2rem;
            color: var(--main-layout-text-color);
            flex-grow: 4;
            margin-bottom: 0;
            .line-clamp(2);
        }
        .video-image-container {
            align-self: center;
            .video-image {
                max-width: 28rem;
                min-width: 15rem;
                object-fit: cover;
            }
        }

        .following-video-popup {
            position: absolute;
            top: 4.5rem;
            right: 1rem;
        }

        .social-icons {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            justify-content: flex-end;
            .social-icon {
                display: inherit;
                padding-right: 2px;
                cursor: pointer;

                &.liked {
                    .b {
                        fill: var(--main-layout-text-color-2);
                    }
                }
            }
            .like-container {
                margin-right: 2.4rem;
            }
            .like-container,
            .unlike-container {
                display: flex;
                align-items: center;
            }

            .unlike-container {
                img {
                    padding-top: 0.6rem;
                }
            }

            .count {
                color: var(--main-layout-color-1);
                font-size: 2rem;
                align-items: center;
                margin-bottom: 0;
            }
        }
        .user-icon {
            border-radius: 100px;
            width: 1.8rem;
            margin-right: 0.5rem;
        }

        .sub-text {
            font-size: 1.2rem;
            color: var(--main-layout-color-1);
            margin-bottom: 0.8rem;
            display: flex;
            align-items: center;

            .creator-name {
                flex-grow: 2;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .following-video-list-item-container {
        .ant-list-item {
            .video-heading-container {
                margin-bottom: 0.5rem;
            }

            .video-heading {
                font-size: 1.8rem;
            }

            .social-icons {
                .count {
                    font-size: 1.8rem;
                }
            }
        }
    }
}
@media only screen and (max-width: 1300px) {
    .following-video-list-item-container {
        grid-column-gap: 1rem;
        .ant-list-item {
            grid-template-columns: 1fr 1.5fr;

            .video-heading {
                font-size: 1.6rem;
            }

            .following-video-popup {
                top: 3.5rem;
            }

            .social-icons {
                .count {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .following-video-list-item-container {
        .video-image-container {
            align-self: center;
        }
        .ant-list-item {
            .video-heading {
                font-size: 1.2rem;
            }

            .social-icons {
                .social-icon {
                    width: 1.6rem;
                }
                .count {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .following-video-list-item-container {
        margin: 0 0.5rem;

        .ant-list-item {
            grid-column-gap: 0.8rem;
            .social-icons {
                justify-content: flex-start;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';