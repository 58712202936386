.bordered-divider {
    position: relative;
    margin: 1rem 0;
    border-top: 0.8rem solid #162d55;
    border-bottom: 0.8rem solid #162d55;
    padding: 0 1.5rem;

    &.hide {
        border-top: 0;
        border-bottom: 0;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';