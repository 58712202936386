.active-nav-item {
  .nav-item {
    background-color: #1d3c71;

    .settings-icon-svg,
    .search-icon-svg {
      .a {
        fill: var(--main-layout-text-color-2);
      }
    }
    #_Icon_Сolor {
      fill: var(--main-layout-text-color-2);
    }
    .tv-icon-svg,
    .following-icon-svg,
    .home-icon-svg,
    .tv-blocked-icon-svg,
    .following-icon-svg {
      .c {
        fill: var(--main-layout-text-color-2);
      }
    }
  }
}
.guest-model {
  .ant-modal-body {
    padding-bottom: 1rem;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .modal-link {
    display: inline-block;
    margin: auto;
    color: #162d55;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: medium;
  }

  .ant-btn {
    text-transform: unset;
    margin: 1rem;
  }
}

.mobile-navigation > a {
  flex: 1;
}

.active-nav-item-prototype {
  // height: 150%;
  .ant-list-item {
    background-color: #577d97;
  }
}

@media only screen and (max-width: 768px) {
  .active-nav-item-prototype {
    height: 150%;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';