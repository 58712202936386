.tier-card-prototype {
  &.ant-card {
    background-color: #162d55;
    padding: 0px 1rem;
    padding-bottom: 0.5rem;
    border-radius: 1rem;
    color: white;
    border: none;
    margin: 1rem 0.5rem;
  }
  .ant-card-body {
    padding: 1rem;
  }
  .tier-item {
    background-color: #162d55;
    padding: 0px 10px;
    padding-bottom: 5px;
    border-radius: 10px;
  }
  .tier-name-price {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
  }
  .tier-desc {
    margin: 0;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';