.cast-svg {
    .b {
        fill: none;
        opacity: 0;
    }
    .c {
        fill: var(--main-layout-color-1);
    }
    .dark {
        fill: #0f1e39;
    }
    .white {
        fill: #fff;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';