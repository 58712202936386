.ant-form {
  label {
    padding-left: 1rem;
  }
  .ant-form-item-label {
    padding-bottom: 0.5rem;
  }
}

.ant-form-item-control-input {
  border-bottom: 2px solid @text-color-dark;
}

.ant-input,
.ant-input-password {
  border: none;
  background: none;
}

.ant-form-item {
  margin-bottom: 4rem;
}


.form-prototype{
  width: 100%;
  margin: 0 0 20px 0;
  padding: 7px;
  .ant-form-item {
    margin: 0;

    .ant-form-item-control-input {
      border-bottom: none;
    }
    .ant-input {
      margin: 1rem 0;
      background-color: white;
      padding: 0.7rem;
      height: 4.4rem;
      border: 2px solid rgba(0, 0, 0, 0.2);
      font-size: 2.2rem;
      text-align: center;

    }

  }
  input {
    border: none;
  }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';