.channel-preview-list {
  .ant-spin-nested-loading {
    .ant-spin-container {
      .home-position-prototype {
        margin-left: 2rem;
      }
    }
  }
}

.home-position-prototype {
  margin: 0rem 2rem;
  // position: sticky;
  // position: absolute;
  height: 10.5rem;
  width: 10.5rem;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #f08507;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;

  &.channel-home {
    position: relative;
  }

  .ant-image {
    // position: sticky;
    // display: flex;
    width: 6.4rem;
    height: 6.4rem;

    .ant-image-img {
      // position: sticky;
      // display: flex;
      width: 6.4rem;
      height: 6.4rem;
    }
  }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';