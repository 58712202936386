@import "@styles/mixins.less";

.mobile-playlists-prototype {
  .playlist-header {
    top: 0;
  }
  .video-container {
    &.active {
      border: 2px solid #ef8508;
    }
    width: 102% !important;
  }

  .video-player-wrapper {
    iframe {
      margin-top: 5rem;
    }

    &.playlists-video-wrapper {
      display: block;
      position: absolute;
      top: 0;
      // height: 90vh;
      overflow: hidden;
    }
  }
  .slick-slider {
    &:last-child {
      margin-bottom: 12rem;
    }
  }
}

.droppable-placeholder {
  height: 1px;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  .hidden-scrollbar-IE();
  &::-webkit-scrollbar {
    display: none;
  }
}

.mobileplaylists-scrollable {
  overflow-y: scroll;
  flex-grow: 1;
  max-height: 50vh;
  position: relative;
  &.hide-overflow {
    max-height: 100vh;
    overflow: hidden;
  }
  .hidden-scrollbar-IE();
  &::-webkit-scrollbar {
    display: none;
  }
}
.full-height {
  max-height: 100%;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';