.kebab-menu-modal-container {
    position: absolute;
    width: 28rem;
    right: 0;

    align-items: center;
    column-gap: 1rem;

    font-size: 1.6rem;
    color: #fff;
    background-color: #3e5070;
    cursor: pointer;
    z-index: 50;
}

.kebab-menu-modal-container.t-100 {
    top: 100%;
}

.b-white {
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 1300px) {
    .kebab-menu-modal-container {
        width: 90%;
    }
}
@media only screen and (max-width: 800px) {
    .kebab-menu-modal-container {
        width: 13rem;
        top: 0;
        z-index: 5;

        .popup-item {
            font-size: 1.2rem;
            padding: 0.2rem;

            svg {
                width: 0.2rem;
            }
        }

        &.sort-popup {
            top: 2.5rem;
            right: 3.5rem;
            font-size: 1.2rem;
            width: 60%;
            .popup-item {
                padding: 0.5rem;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .kebab-menu-modal-container {
        top: 5rem;
        right: 2rem;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';