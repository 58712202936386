.confirm-paste-modal-prototype {
  .para {
    font-size: 1.8rem;
  }
  .ant-modal-footer {
    display: flex;

    .ant-btn-prototype {
      max-width: initial;
      min-width: initial;
      padding: 0.5rem 0.8rem;
      font-size: 1.4rem;

      &.dark {
        background-color: #173b50;
        border: #173b50;
      }
      &.white {
        border: 1px solid #000;
        color: #000;
      }
    }
  }
}

@media only screen and(max-width:768px) {
  .confirm-paste-modal-prototype {
    .ant-modal-content {
      width: 100%;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';