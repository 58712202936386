@keyframes animateOnRender {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.accept-cookies {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1001;
  user-select: none;
  border-radius: 1rem;
  padding: 2rem;
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.4);

  animation: animateOnRender 0.6s ease-in-out;

  a {
    text-decoration: underline;
    color: #74b0f4;
  }

  &__close {
    background-image: url(../../../../../assets/icons/closeIconPrototype.svg);
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    top: -1rem;
    right: -1rem;

    cursor: pointer;
  }

  &__content {
    text-align: center;
    font-size: 2rem;
    color: black;
  }

  &__button {
    padding: 2px 3rem;
    display: inline-block;
    color: white;
    background-color: #f08605;
    font-size: 2rem;
    border-radius: 1rem;
    cursor: pointer;

    outline: none;
    border: none;

    align-self: center;
    margin-top: 2rem;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';