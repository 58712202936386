.popup-blocked-modal-prototype {
  top: 5%;
  .ant-modal-content {
    width: 100%;
  }
  .ant-modal-body {
    padding: .5rem;
    width: 95%;
    margin: auto;

    .ant-typography {
      font-size: 1.6rem;
      margin-bottom: 0.2rem;
    }
  }

  .ant-modal-footer {
    padding: 0;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';