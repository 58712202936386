@import "@styles/mixins.less";

.add-video-modal
 {
    &.ant-modal {
        top: 5%;
        .ant-modal-body {
            padding-bottom: 0;
        }
        .ant-modal-body {
            & form {
                margin: 0 1rem;
            }
        }
    }

    .new-playlist-input-container {
        max-height: 23rem;
        overflow-y: scroll;
        .hidden-scrollbar-IE();
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .add-new-link-container {
        text-align: center;
        border-bottom: 2px solid #c8cbd0;
        line-height: 0.1em;
        margin: 0 auto;

        .plus-icon-container {
            background: #fff;
            position: relative;
            top: 1.2rem;
            padding: 0 0.3rem;
        }
        svg {
            cursor: pointer;
        }

        .ant-form-item {
            &:last-child {
                margin-bottom: 1rem;
            }
        }
    }
    .ant-modal-body {
        .close-modal-icon {
            position: absolute;
            right: 0;
        }

        .new-playlist-link-input {
            padding-right: 3rem;
        }
    }

    .add-video-btn {
        width: 28rem;
        max-height: 4rem;
    }

    .create-new-playlist {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        .text {
            display: inline;
            color: #f08507;
            border-bottom: 1px solid currentColor;
            cursor: pointer;
        }
        .or {
            position: absolute;
            top: -5.3rem;
            background: #fff;
            width: 3rem;
            text-align: center;
            color: #707070;
            pointer-events: none;
        }
    }

    .choose-playlist {
        margin-bottom: 3rem;
        margin-top: 2rem;
        border-bottom: 1px solid #ccc;

        div {
            display: grid;
            grid-template-columns: 90% 10%;
            grid-template-rows: auto;
            grid-column-gap: 1rem;
            align-items: center;
            cursor: pointer;
            margin-bottom: 3rem;
        }

        .choose-playlist-text {
            padding-left: 1rem;
            color: @text-color-dark;
            margin-bottom: 0;
        }
    }
    .playlists-list {
        max-height: 30rem;
        overflow-y: scroll;
        .hidden-scrollbar-IE();
        &::-webkit-scrollbar {
            display: none;
        }

        .ant-list-item {
            padding: 1rem;
            &:hover {
                background: #a5b1c6;
            }
        }
    }
}

@media only screen and (max-height: 768px) {
    .add-video-modal {
        &.ant-modal {
            top: 3%;
        }

        .ant-modal-body {
            padding: 1rem 2rem;
        }

        .ant-form-item {
            margin-bottom: 2rem;
        }

        .choose-playlist {
            margin: 1rem 0;
        }

        .new-playlist-input-container {
            max-height: 15rem;
        }
        .playlists-list {
            max-height: 17rem;
        }

        .ant-modal-content {
            max-width: 90%;
        }

        .add-video-btn {
            width: 80%;
        }
        .playlists-list {
            max-height: 20rem;
        }

        .ant-modal-footer {
            padding: 0.5rem;

            .add-video-btn {
                margin: 1rem auto;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';