@import "@styles/mixins.less";

.create-team-modal {
  top: 15%;

  .custom-scrollbar();
  .ant-modal-content,
  .ant-modal-header {
    background: rgba(11, 90, 142);
    color: #fff;
  }

  .ant-modal-body {
    max-height: 60vh;
    overflow-y: scroll;
    .custom-scrollbar();
  }

  .input-container {
    display: flex;
    flex-direction: column;
  }

  .img-input-container {
    display: flex;
  }

  .ant-modal-footer {
    justify-content: center;
  }

  .confirm-btn {
    justify-content: center;
    background: #ef8508;
    outline: none;
    border-radius: 10px;
    border: none;
    padding: 5px 15px;
    color: white;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    min-width: 120px;
  }

  .err-msg {
    color: #ef8508;
    min-height: 24px;
  }

  .input-container {
    input {
      margin: 0.5rem 0;
      border: 1px solid #ef8508;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      outline: none;
      color: #000;
    }
  }

  .editable-img {
    margin-top: 20px;

    input {
      margin: 0.5rem 0;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      outline: none;
      color: #000;
    }
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';