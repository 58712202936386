.language-picker-prototype {
  position: absolute;
  left: 2rem;
  top: 3rem;
  .ant-select {
    color: white;
    img {
      width: 4rem;
    }

    .ant-select-arrow {
      color: white;
    }
  }

  .lang-title {
    font-size: 15px;
    color: #fff;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';