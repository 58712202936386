.delete-item-modal-prototype {
  .ant-modal-content {
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.5);
  }
  .footer {
    display: flex;
    .response-buttons-prototype {
      margin: 0;
      padding: 0 1rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';