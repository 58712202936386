.privacy-container-prototype {
  display: block;
  font-family: Lato, sans-serif;
  margin: 1rem;
  background-color: #fff;
  padding: 1rem 3rem;
  border-radius: 0.8rem;
  // position: relative;

  // .ant-modal {
  //   &.cookies-modal-prototype {
  //     .ant-modal-content {
  //       .ant-modal-close-x {
  //         // border: none !important;
  //         right: 20px !important;
  //       }
  //     }
  //   }
  // }

  // .ant-modal-close {
  //   // border: none !important;
  //   right: 20 !important;
  // }

  //utility classes
  .align-center {
    align-items: center !important;
  }
  .justify-between {
    justify-content: space-between !important;
  }
  .flex {
    display: flex !important;
  }
  .list-style-none {
    list-style-type: none !important;
  }
  .margin-10 {
    margin-top: 1rem !important;
  }

  .border-1 {
    border: 1px solid #1c2832;
    padding: 0.4rem;
  }
  .span-small {
    font-size: 1.4rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  //

  .privacy-policy-secondary-title {
    margin: 1.5rem 0px 0px 0px;
    display: block;
    font-weight: bold;
    color: #1c3c71;
    font-size: 1.6rem;
  }

  .privacy-policy-tertiary-title {
    font-size: 1.7rem;
    margin-left: 1.5rem;
    color: #1c3c71;
  }

  #back-button {
    border: 0;
    padding: 0.8rem 1.5rem;
    background-color: #f08605;
    color: #fff;
    border-radius: 0.5rem;
    font-size: 1.3rem;
  }

  .privacy-policy-text-block {
    margin: 1.5rem 0px 1.5rem 0px;
    color: #1c3c71;
  }

  .privacy-policy-ol {
    color: #1c3c71;

    li {
      margin-top: 0.5rem;
    }
  }

  .privacy-list-items {
    margin-top: 0.5rem;
    margin-left: -1rem;
  }

  .privacy-p-small {
    font-size: 1.6rem;
    margin-left: 1.5rem;
    color: #1c3c71;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 1rem;
    font-size: 1.2rem;
  }
  #back-button {
    border: 0;
    padding: 0.8rem 1.5rem;
    background-color: #f08605;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';