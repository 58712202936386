.spinner-overlay-prototype {
  height: 100vh;
  position: absolute;
  left: 50%;
  // width: 100vw;
  // background-color: rgba(0, 0, 0, 0.35);
  z-index: 10000;
}

.spinner-prototype {
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform(-50%, -50%);
  font-size: 1rem;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: spinnerAnimation 1s infinite linear;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  border-radius: 100rem;
}

@-webkit-keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';