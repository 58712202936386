.profile-info-modal {
    .ant-modal-footer {
        text-align: center;
    }
    .enter-profile-btn {
        display: inline;
        margin: 0 auto;
        min-width: 15rem;
    }
    .para {
        font-size: 1.6rem;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .profile-info-modal {
        .ant-modal-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;

            button {
                margin: auto !important;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';