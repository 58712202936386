.favorites-list-item-container {
    margin: auto;
    position: relative;

    .ant-image {
        width: 50%;
        height: 50%;
        &-img {
            height: 100%;
        }
    }
    &.favorites-bg {
        background-color: #0f1e39;
    }

    .icon-container {
        .play-buttons-menu {
            border-radius: 100px;
        }
    }

    &.active {
        .ant-list-item {
            background-color: #162d55;
            border-radius: 0.8rem;

            .title {
                color: #f6f6f6;
            }

            &:hover {
                .title {
                    color: #162d55;
                }
            }
        }
    }

    .ant-list-item {
        display: grid;
        grid-template-columns: auto 4fr max-content;
        grid-column-gap: 2.4rem;
        padding: 1rem 2.4rem;

        &:hover {
            background-color: #1d3c71;
            border-radius: 1rem;
            transition: all 0.3s;
            cursor: pointer;
        }
    }
    .playlist-statistic-container {
        display: grid;
        grid-template-columns: max-content 2rem max-content;
        align-items: center;
        justify-items: center;

        .para {
            margin-bottom: 0;
        }
    }

    &.active {
        .playlist-statistic-container {
            background-color: #f6f6f6;
            border-radius: 1rem;
            padding: 4px;
            width: fit-content;
        }
    }

    .title {
        color: var(--main-layout-text-color);
        font-size: 2rem;
        text-transform: capitalize;
        max-width: 95%;
        overflow-wrap: anywhere;
    }
    .para {
        color: var(--main-layout-color-1);
        font-size: 1.4rem;
    }
    .icon-container {
        background-color: var(--main-layout-bg-2);
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.light-bg {
            background-color: #1d3c71;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .favorites-list-item-container {
        .ant-list-item {
            padding: 1rem 0;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .favorites-list-item-container {
        .ant-list-item {
            grid-column-gap: 1.4rem;
            padding: 1rem;

            .title {
                font-size: 1.8rem;
            }
            .para {
                font-size: 1.2rem;
            }

            .icon-container {
                width: 3.8rem;
                height: 3.8rem;
            }
        }
    }
}
@media only screen and (max-width: 900px) {
    .favorites-list-item-container {
        .ant-list-item {
            grid-column-gap: 1.2rem;
            padding: 0.5rem;

            .title {
                font-size: 1.5rem;
            }
            .para {
                font-size: 1rem;
            }

            .icon-container {
                width: 3.4rem;
                height: 3.4rem;
            }
        }
    }
}

@media only screen and (max-width: 840px) {
    .favorites-list-item-container {
        .ant-list-item {
            grid-column-gap: 1rem;
            padding: 0.4rem;

            .title {
                font-size: 1.3rem;
            }
            .para {
                font-size: 0.8rem;
            }

            .icon-container {
                width: 3.2rem;
                height: 3.2rem;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .favorites-videos-list {
        .favorites-video-list-item-container {
            padding: 0.4rem 0rem;
            .ant-list-item {
                padding: 0.9rem;
                background-color: #0f1e39;
                grid-column-gap: 1rem;
                .video-image-container {
                    .ant-image img {
                        width: 16rem;
                        height: 12rem;
                    }
                }

                .social-icons {
                    position: absolute;
                    bottom: 2rem;
                    right: 2rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .favorites-list-item-container {
        .ant-list-item {
            min-height: 8rem;
            .title{
                font-size: 2rem;
            }
            .playlist-statistic-container{
                .para{
                    font-size: 1.4rem;
                    letter-spacing: 0.024rem;
                }
            }
            .ant-image {
                width: 90%;
                height: 90%;

            }
        }

    }
}

@media only screen and (max-width: 600px) {
    .favorites-list-item-container {

        .ant-list-item {
            &:hover {
                background-color: transparent;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';