.add-website-to-home-modal-prototype {
  top: 0;
  .ant-modal-content {
    width: 100%;

    .ant-modal-close {
      position: absolute;
      right: -1rem;
      top: -1rem;
    }
  }
  .ant-modal-body {
    padding: 1rem;
    width: 95%;
    margin: auto;
    .ant-image {
      display: inline;
      img {
        display: inline-block;
        width: 3rem;
        transform: translateY(-5px);
      }
    }

    .ant-typography {
      font-size: 1.6rem;
    }

    .checkbox-text-container {
      display: flex;
      width: 90%;
      margin: auto;
      .text {
        padding-left: 1rem;
      }
    }
  }

  .ant-modal-footer {
    padding-bottom: 0;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';