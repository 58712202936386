.search-suggestion-list-prototype {
  width: 95%;
  margin: 0 auto;
  background-color: white;
  max-height: 50vh;
  overflow-y: scroll;
}

.search-result-container {
  .result-container {
    display: flex;
    align-items: center;
    background-color: #eeeeee;
    margin: 0.5rem 0rem;
    .ant-image {
      display: block;
      width: 70%;
      img {
        max-width: 5rem;
      }
    }
    .ant-typography {
      font-size: 1.8rem;
    }
  }

  .result-item {
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    margin: 1rem;
    .ant-typography {
      font-size: 1.8rem;
      margin-bottom: 0;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';