.forgot-password-form {
    margin-top: 10rem;
    .ant-form-item {
        margin-bottom: 4rem !important;
    }
    .forget-password-btn {
        display: block;
        margin: 0 auto;
        width: 90%;
        margin-top: 14rem;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';