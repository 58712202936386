@import "@styles/mixins.less";

.add-modal {
  top: 6%;
  &.update-playlist-modal{
    .ant-modal-body {
      height: 60vh;
      overflow: auto;
      .custom-scrollbar();
    }
  }
  .ant-modal-body {
    border-top: unset;
  }
  .ant-modal-content,
  .ant-modal-header {
    background-color: rgba(11, 90, 142);
  }
  .ant-modal-header {
    padding-top: 1px;
    padding-bottom: 2.5rem;
    margin-top: 0;
  }
  .ant-modal-title {
    margin-top: 10%;
    font-weight: 600;
    color: white;
    font-size: 2.5rem;
  }
  .ant-modal-content {
    border-radius: unset;
    width: 90%;
    padding: 0 1rem;
  }

  .ant-modal-body {
    .tag-items {
      display: inline;

      .tag-items-container {
        margin: 0;
        margin-bottom: 1rem;
        max-height: 15rem;
        overflow-y: scroll;
        .custom-scrollbar();
      }
    }
    .ant-tag {
      width: max-content;
      margin-bottom: 0.5rem;
      background: rgb(7, 67, 107);
      border: 1px solid rgb(11, 90, 142);
      border-radius: 1rem;
      padding: 0.5rem;
      font-size: 1.5rem;
      color: #fff;
    }
  }
  .ant-modal-body > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  .ant-modal-body > div > label {
    color: white;
    font-family: sans-serif;
    font-size: 1.5rem;
    margin-bottom: 7px;
    font-weight: 500;
  }
  .ant-modal-body > div > input {
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    padding: 3px 10px;
  }
  .ant-modal-body > div > select {
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    padding: 3px 10px;
  }
  .ant-modal-body > div > select :focus {
    border: none;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
  }
  .ant-modal-footer > button {
    background-color: #f08605;
    border: unset;
    color: white;
    padding: 6px 3rem;
    border-radius: 4px;
    font-size: 16px;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .ant-modal-close-icon {
    background-color: #333;
    padding: 8px;
    border-radius: 50%;
  }
  .ant-modal-close-icon > svg {
    color: white;
    font-size: 1.5rem;
  }
  .size-validation-error {
    color: #ffff81;
    font-size: 18px;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';