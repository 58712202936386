.playlist-card-home-position {
  margin: 0 !important;
  padding: 0 !important;
  height: 6rem !important;
  width: 6rem !important;
  display: block;

  position: absolute;
  .ant-image {
    width: 100%;
    height: 100%;
    .ant-image-img {
      padding: 0.5rem;
      margin: 0;
    }
  }
}
.playlist-card {
  width: 99%;
  -webkit-user-select: none;
  user-select: none;
  .ant-card-body {
    font-family: sans-serif;
    font-size: 1.4rem;
    color: white;
    background-color: #173b50;
    border-bottom: 1rem solid #091820;
    max-height: 10rem;
  }
  .ant-card-cover {
    position: relative;
  }
  .ant-image {
    width: 100%;

    .ant-image-img {
      display: inline-block;
      min-height: 28rem;
      max-height: 33rem;
      object-fit: cover;
    }

    .home-position-title {
      width: auto;
      display: inline-block;
      min-height: 20px;
      width: 20px;
      height: 20px;
    }
  }

  .infobox-prototype {
    transform: translateY(-4rem);
    z-index: 15;
  }
}

.ant-card-bordered {
  border: 1px solid #173b50;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';