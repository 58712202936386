.balance-display-prototype {
  background-color: #162d55;
  font-size: 3rem;
  padding: 3rem 2rem;
  width: 30rem;
  text-align: center;
  border-radius: 2rem;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 2rem;
  .balance {
    margin-bottom: 0;
    color: #f08507;
    font-weight: bold;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';