.copy-in-hart-modal {
    padding: 0 20px;

    .ant-typography {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .ant-image {
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
    }

    .checkbox-text-container {
        display: flex;
        justify-content: center;
    }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';