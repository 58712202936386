.channel-menu-prototype {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid white;
  opacity: 0.7;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  .kebab-icon-svg {
    width: 1.8rem;
    height: 1.8rem;
    &:hover {
      background-color: transparent;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';