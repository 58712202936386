.recent-views-icon {
    width: 4.8rem;
    height: 4.8rem;
    z-index: 999;
    .s0 {
        fill: none;
    }
    .ant-image {
        // position: sticky;
        // display: flex;
        width: 4.8rem;
        height: 4.8rem;
    
        .ant-image-img {
          // position: sticky;
          // display: flex;
          width: 4.8rem;
          height: 4.8rem;
        }
    }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';