.tier-buy-modal-prototype {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-body {
    padding: 4rem 6rem 0 6rem;
  }
  .msg {
    margin: 0.5rem 0;
    font-size: 1.7rem;
  }
  .error-msg {
    color: #e31b1b;
  }
  .success-msg {
    color: #10b981;
  }
  .discount-text {
    font-size: 2.08rem;
  }
  .price {
    font-size: 2.88rem;
    font-weight: bold;
  }
  .coupon-code-input {
    border: 1px solid #000;
    padding: 1rem 0.5rem;
    width: 100%;
    border: 1px solid #646464;
    border-radius: 0.5rem;
    outline: none;
    transition: border 0.1s ease-in-out;
  }
  .content-container {
    display: grid;
    grid-template-columns: 5fr 1fr;
  }
  .btn-container {
    display: flex;
    flex-direction: row-reverse;
    .confirm-btn {
      border: none;
      margin: 1rem;
      display: inline-block;
      margin-left: auto;
      padding: 0;
      margin-right: 0;
      text-align: right;
      min-width: 12rem;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .tier-buy-modal-prototype {
    .ant-modal-content {
      width: 90%;
    }
    .ant-modal-body {
      padding: 4rem 2rem 0 2rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';