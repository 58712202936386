.playlist-statistics-details {
  .date-range-label-container {
    display: grid;
    grid-template-columns: max-content min-content;
    align-items: center;
    margin-left: 1rem;
  }
  .playlist-stats-calendar-label {
    display: block;
    color: #fff;
    font-size: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    margin: auto;
    padding-right: 0.5rem;
  }

  .ant-image {
    display: inline-block;
    .arrow-img {
      display: block;
      width: 2rem;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .playlist-stats-calendar-container {
    position: relative;
    justify-content: center;
    display: flex;
    max-width: 99%;
    margin: auto;

    .rdrDateRangePickerWrapper {
      width: 99%;
      margin: auto;

      .rdrCalendarWrapper {
        width: 100%;
      }

      .rdrMonth {
        width: 100%;
      }
    }

    // .rdrDefinedRangesWrapper {
    //   display: none;
    // }
    .filter-btn {
      position: absolute;
      bottom: 0.5rem;
      left: 1rem;
      background-color: rgb(61, 145, 255);
      color: #fff;
      &:hover {
        color: #fff;
        border-color: #fff;
      }
      &:disabled{
        opacity: 0.5;
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';