@import "@styles/mixins.less";
.ant-modal-wrap {
  overflow: auto !important;

  .custom-scrollbar();
}
.ant-modal-header {
  margin-top: 0 !important;
}
.create-playlist-modal-prototype {
  padding: 0;

  input {
    font-size: 16px;
  }

  .ant-modal-body {
    padding-top: 0;
    max-height: 60vh;
    overflow-y: scroll;
    .custom-scrollbar();

  }
  .tag-items {
    display: flex;

    .tag-items-container {
      margin: 0;
      margin-bottom: 1rem;
      max-height: 15rem;
      overflow-y: scroll;
      .custom-scrollbar();
    }
  }
  .ant-tag {
    width: max-content;
    margin-bottom: 0.5rem;
    background: rgb(7, 67, 107);
    border: 1px solid rgb(11, 90, 142);
    border-radius: 1rem;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: #fff;
  }

  .create-playlist-alert {
    &.ant-alert {
      display: flex;
      flex-direction: row;
    }
  }

  button:disabled {
    opacity: 0.65;
  }

  .size-validation-error {
    color: #ffff81;
    font-size: 18px;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';