.line-clamp(@line) {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}

.hidden-scrollbar-IE() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scrollbar() {
  *,
  *:before,
  *:after {
    box-sizing: inherit;
    scrollbar-width: thin;
    scrollbar-color: #f08605 transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    scrollbar-width: thin;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f08605;
    scrollbar-color: #f08605 transparent;
    border-radius: 20px;
  }
}

.flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}


.disable-iphone-default-menu(){
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
}