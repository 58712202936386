.error-feedback-prototype {
  font-family: "Helvetica Neue Light", "Lucida Grande", "Calibri", "Arial",
    sans-serif;
  font-size: 2.1rem;
  color: #ffff81;
  padding: 0.8rem;
  z-index: 15;

  &.success {
    color: #eee;
  }

  &.danger {
    color: #dc3545;
    font-weight: bolder;
    font-size: 1.3rem;
  }

}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';