@import "@styles/mixins.less";

.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal {
      &.cookies-modal-prototype {
        width: 100% !important;
      }
    }
  }
}

.ant-modal {
  &.cookies-modal-prototype {
    .ant-modal-body {
      border: none !important;
    }

    .ant-modal-content {
      .ant-modal-body {
        .ant-image {
          .ant-image-img {
            &.choice-logo {
              width: 0rem;
            }
          }
        }
      }
    }
  }
}

.cookies-modal-prototype {
  top: 0;
  max-height: 95vh;
  // overflow-x: auto;
  .bg-black {
    background-color: transparent;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-body {
    height: 85%;
  }
  .privacy-container-prototype {
    overflow: scroll;
    height: 80vh;
    .custom-scrollbar();
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';