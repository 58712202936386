.mobile-reset-password-prototype {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1D267A !important;
  color: white;

  .choice-header-prototype {
    margin-bottom: 1.5rem;
    color: white;
    font-weight: 500;
    font-size: 40px;
    font-family: Arial, serif;
    line-height: 1.2;
  }

  .reset-password-prototype {
    width: 60%;
  }

  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';