.move-to-video-modal {
    &.ant-modal {
        top: 5%;
        .ant-modal-body {
            padding-bottom: 0;

            form {
                margin: 0;
            }
        }
        .playlists-list {
            max-height: 60rem;
            overflow-y: scroll;
            // .hidden-scrollbar-IE();
            &::-webkit-scrollbar {
                display: none;
            }

            .ant-list-item {
                padding: 1rem;
                &:hover {
                    background: #a5b1c6;
                }

                .icon-container {
                    background-color: #1d3c71;
                }
                .ant-typography {
                    color: #0f1e39;
                    margin-bottom: 0.2rem;
                }

                .para {
                    color: #788aaa;
                }
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';