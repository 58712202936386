.create-group-modal-prototype {
  height: 95%;
  top: 1%;

  .group-desc {
    position: sticky;
    top: 5rem;
    background-color: #fff;
  }



  .group-name-input {
    display: block;
    margin: auto;
    border: 1px solid;
  }
  .title {
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
  .footer {
    display: block;
    position: absolute;
    bottom: 0;
    button {
      display: inline-block;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';