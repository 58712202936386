@import "@styles/mixins.less";

.sub-bar-prototype {
  .disable-iphone-default-menu();
  .subbar-item-img {
    pointer-events: none;
    .disable-iphone-default-menu();
  }
  .search-item {
    background-size: 30px 30px;
    position: relative;
    z-index: 10;
    background-color: #5e849d66;
    height: 10.5rem;
    .subbar-item {
      border-color: transparent !important;
      justify-content: center;

      &:hover {
        background-color: #5e849d66;
        cursor: pointer;
      }
    }
    img {
      width: 3rem;
    }
  }

  .subbar-item-container {
    position: relative;
    .subbar-content-container {
      display: block;
      width: 100%;
      .ant-image {
        display: block;
      }

      .subbar-svg-container {
        max-height: 4.8rem;
      }
    }
    &.search {
      margin-top: 1.5rem;

      .ant-image {
        .ant-image-img {
          padding: 0.8rem;
        }
      }
    }
  }

  .ant-spin-container {
    display: flex;
  }
  .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid white;
  }

  .subbar-item-context {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2.4rem;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid white;
    margin: 1.2rem 1.2rem;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    padding: 0;

    .ant-image {
      height: 100%;
      img {
        border-radius: 50%;
        max-height: 4.7rem;
        width: 100%;
        align-self: center;
        height: 95%;
        object-fit: contain;
      }
    }
  }
  .openable-item-content {
    display: flex;
  }
  .subbar-item {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2.4rem;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid white;
    margin: 1.2rem 1.2rem;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    padding: 0;

    .ant-image {
      height: 100%;
      img {
        border-radius: 50%;
        max-height: 4.7rem;
        width: 100%;
        align-self: center;
        height: 95%;
        object-fit: contain;
      }
    }

    &.ant-list-split .ant-list-item:last-child {
      border-bottom: 1px solid white;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0);
      cursor: pointer;
    }

    &.subbar-item-openable {
      border: 1px solid white;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: white;
        top: 2rem;
        right: -3px;
        transform-origin: -2.1rem 3px;
        transition: all 0.3s ease-in-out;
      }

      &::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: white;
        top: 2rem;
        right: -3px;
        transform-origin: -2.1rem 3px;
        transition: all 0.3s ease-in-out;
      }
      &.subbar-item-openable-opened {
        border: none;
        background-color: rgba(255, 255, 255, 0.15);
        transition: all 0.3s ease-in-out;
        box-shadow: -1px 0 0 0.7px white;
      }

      &.subbar-item-openable-opened::after {
        transform: rotate(-90deg);
      }
      &.subbar-item-openable-opened::before {
        transform: rotate(90deg);
      }
    }
  }

  .subbar-item-para {
    font-size: 1rem;
    padding-top: 1rem;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    white-space: break-spaces;
    line-height: 1;
    margin-top: 0.3rem;
    width: 7rem;
  }

  .flex-container {
    display: flex;

    // this margin makes playlists container to scroll back
    // on drag mode, IDK fix if u whant
    // &:last-child{
    //   margin-right: 2rem;
    // }

    &.openable {
      margin-right: 0rem;
    }
  }

  .openable-content {
    display: flex;
    transform: scaleX(0);
    width: 0;
    background-color: rgba(23, 59, 80, 0.15);
    transform-origin: left center;
    transition: all 0.3s ease-in-out;
    min-height: 7.8rem;
    height: 0;
    position: relative;
    // margin-top: 3.5rem;
    // top: -12.25rem;
    // left: 6.25rem;

    &.opened {
      transform: scaleX(1);
      display: flex;
      //align-items:center;
      // position: relative;
      // margin-top: 9rem;
      // left: 6.25rem;
      transition: all 0.3s ease-in-out;
      // width: 100%;
    }
  }

  .red-dots {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }

  .permission-playlist {
    position: absolute;
    top: -1.5rem;
    right: -2.3rem;
    .ant-image {
      img {
        height: 50%;
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';