.auth-header-mobile {
    background-color: #1c3d71;
    background-image: url("../../../../../assets/images/auth-mobile-circle-bg.svg"),
        radial-gradient(at top ,#2e5fb2, #1c3d71);
    background-repeat: no-repeat;
    min-height: 16rem;
    background-position: right;
    border-radius: 0 0 1rem 1rem;

    .ant-image {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
    }
    .ant-typography {
        text-align: center;
        color: #fff;
        font-size: 2rem;
        font-weight: normal;
        margin-top: 0 !important;
    }
    .choice-logo {
        max-width: 15rem;

        &.white-logo {
            margin-bottom: 2.5rem;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';