.homepage-container.playlis-message {
    min-height: 0;

    .home-welcome-container {
        width: 100%;
        padding-top: 0rem;
        padding-bottom: 0rem !important;
  
        .description-content {
            font-size: 2rem;
        & > *:not(a) {
            color: #fff;
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        .home-welcome-container {
            padding-bottom: 0rem;
        }
    }

}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';