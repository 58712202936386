.share-modal {
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        // align-items: center;
    }
    .social-icons-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2rem;
        justify-items: center;
    }

    img {
        max-width: 48px;
        max-height: 48px;
    }

    p {
        margin: 1.6rem 0;
        align-self: center;
        font-size: 1.6rem;
        font-weight: 600;
        color: #707070;
    }

    .ant-input {
        position: relative;
        border: 1px solid #ebebeb;
        border-right: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        outline: none;
    }

    .ant-input:focus,
    .ant-input:hover {
        border-color: none;
        box-shadow: none;
    }

    .ant-input-search .ant-input:hover,
    .ant-input-search .ant-input:focus {
        border-color: none !important;
    }

    // .ant-modal .ant-btn;
    .ant-input-search-button {
        margin: 0;
        min-width: auto;
        min-height: auto;

        text-shadow: none;
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: unset;

        box-shadow: none;
        border: 1px solid #ebebeb;
        border-radius: 0 8px 8px 0 !important;
        border-left: none !important;

        color: #ef8508;
        background-color: #fff;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 60%;
            background-color: #9e9e9e;
            border-radius: 100px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .share-modal {
        .ant-modal-content {
            max-width: 95%;
        }

        .social-icons-container {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
        }
    }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';