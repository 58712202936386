.ant-list {
    &.ant-list-split {
      &.profile-list {
         display: flex;
         max-width: 100%;
         width: 100%;
         position: relative;
       }
       .ant-spin-container {
         height: 10.5rem;
         width: 100%;
         display: flex;
         align-content: center;
         justify-content: space-between;
         position: relative;
  
       }
     }
   }
  
  .sub-bar-prototype {
    .statistics-icon {
      display: inline-block;
      margin: 2rem 3rem;

      .ant-image {
        .ant-image-img {
          &.profile-choice-logo {
            margin: 0rem 2rem;
            height: 10.5rem;
            width: 10.5rem;
            left: 0;
            right: 0;
            z-index: 999;
            background-color: #f08507;
            display: flex;
            align-items: center;
            padding-left: 2rem;
            padding-right: 2rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  .sub-bar-prototype {
    .choice-icon {
      display: inline-block;
      margin: 2rem 3rem;
      width: 70px;
    }


    .teams-icon {
      cursor: pointer;
      width: 60px;
      height: 60px;
      margin: 0 1.6rem;
    }
  }
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';