// auth elements for registration and login pages
.auth-row {
    height: 100vh;
}
.auth-dark-col {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../assets/images/auth-large-bg.svg");
    background-size: cover;
    background-position: center;
}

.auth-light-col {
    background-repeat: no-repeat;
    background-position: top;
    // background-size: contain;
    background-color: #f6f6f6;
}
.auth-gradient-bg-1 {
    background-image: url("../../../assets/images/auth-gradient-bg-1.svg");
}
.auth-gradient-bg-2 {
    background-image: url("../../../assets/images/auth-gradient-bg-2.svg");
}
//

.auth-mobile-layout {
    background-color: #f6f6f6;
    min-height: 100vh;
    background-image: url("../../../assets/images/auth-mobile-bottom-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.ant-form-item-label label {
    color: @text-color-dark;
    font-weight: 500;
    letter-spacing: 0.026rem;
    font-size: 2rem;
    font-family: inherit;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';