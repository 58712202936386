.video-player-controls {
  .top-controls {
    position: absolute;
    height: 8.4rem;
    background-color: transparent;
    position: absolute;
    top: 0;
    display: flex;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    background-color: #000;
    width: 100%;
    justify-content: space-between;
    padding: 2rem;
    color: #fff;
    transform: translateY(-7.5rem);

    .right-controls{
      display: flex;
      justify-content: right;
      align-items: center;
    }

    .close-player-svg {
      width: 2.24rem;
      height: 2.24rem;
      margin-left: 3rem;
    }

    .video-title {
      font-family: sans-serif;
      font-size: 18px;
      color: white;
    }
  }
  .bottom-controls {
    position: absolute;
    bottom: 0;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';