.mobile-tiers {
  .tier-video-player-wrapper {
    overflow-y: hidden;
    position: absolute;
    top: 0;
  }

  .mobile-playlists-prototype .slick-slider:last-child{
    margin-bottom: 0;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';