.terms-modal {
    height: 80rem;
    width: 100% !important;
    top: 10%;

    .ant-modal-title {
        font-size: 2rem;
    }
    .ant-modal-content {
        width: 88rem;
        height: 70rem;
        border-radius: 0;
    }

    .ant-modal-body {
        font-size: 2.4rem;
        line-height: 1.5;
        overflow-y: scroll;
        height: 50rem;
    }

    .ant-modal-footer {
        display: block;
        text-align: center;
        button {
            display: inline;

            &:not(:last-child) {
                margin-right: 1.6rem;
            }
            &:last-child {
                margin-left: 1.6rem;
            }

            &:hover {
                max-width: 20rem;
                min-width: 20rem;
            }
        }
    }

    .light-btn {
        background-color: #d2d8e3;
        color: #0f1e39;
        border: none;
    }
}

.terms-modal-mobile {
    font-size: 2rem;

    .ant-modal-title {
        font-size: 2rem;
        color: #000;
    }
    .ant-modal-body {
        border-top: none;
        font-size: 1.6rem;

        .ant-typography {
            color: #00000099;
        }
    }
}

@media only screen and (max-width: 992px) {
    .terms-modal {
        .ant-modal-content {
            width: 80%;
            border-radius: 0;
        }
    }

    .terms-modal-mobile {
        .ant-modal-content {
            width: 90%;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';