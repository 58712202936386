.editable-image-container {
  .img-inputs {
    margin-bottom: 0.5rem;
    p {
      color: #fff;
      width: 30%;
      text-align: center;
      margin: 0;
    }
    .img-input-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 1rem;

      label {
        color: #fff;
      }
    }

    @media only screen and(max-width: 480px) {
      p {
        margin: 5px auto;
      }

      .img-input-container {
        display: flex;
        gap: 5px;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .crop-controls {
    margin-bottom: 10px;

    .url-input {
      border-radius: 1rem;
      border: 2px solid rgba(0, 0, 0, 0.2);
      padding: 3px 10px;
    }

    .cropable-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .crop-label {
      color: #fff;
    }

    .img-upload-btn {
      width: 20rem;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      display: inline-block;
      background: #f08605;
      border: 1px solid #999;
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 10pt;
      color: #fff;
      font: inherit;
      margin-bottom: 0rem;
      
      &:hover {
        border-color: black;
      }
    }
      

    .input-type-file {
      width: 20rem;
      margin-left: 20px;
      display: none;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }
      &::before {
        // content: "Upload channel image";
        content: attr(data-content);

        display: inline-block;
        background: #f08605;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 10pt;
        color: #fff;
        font: inherit;
        margin-bottom: 0rem;
      }
      &:hover::before {
        border-color: black;
      }
    }
  }
  .Crop-Controls > * {
    margin-bottom: 3px;
  }

  img {
    max-width: 100%;
  }

  .circle {
    .ReactCrop__crop-selection,
    canvas {
      border-radius: 10rem;
    }
  }

  .rectangle {
    .ReactCrop__crop-selection,
    canvas {
      border-radius: 8px;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';