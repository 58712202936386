.channel-preview-list {
  .playlists-not-followed-history-list {
    margin: 0 1rem;
    display: flex;
    align-items: center;
  }
}
.sub-bar-prototype__content {
  .ant-list {
    .spinner {
      margin-left: 8rem !important;
    }
    &.ant-list-split {
      &.channel-preview-list {
        display: flex;
        max-width: 100%;
        width: 100%;
        position: relative;
      }
      .default-playlists-list {
        display: flex;
        height: 98%;
        align-items: center;
      }

      .ant-spin-container {
        height: 10.5rem;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        position: relative;

        .recent-views-icon {
          margin-left: 2rem;
        }

        .home-position-prototype {
          margin-left: 0;
          margin-right: 0;
        }

        .watch-later-icon {
          margin: 0 2rem;
        }
        // .ant-image-img {
        //   margin: 0rem 5;
        // }
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';