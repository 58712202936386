.share-modal-prototype {
  // max-width: 520px;
  // width: auto !important;
  .ant-modal-body {
    padding-bottom: 4rem;
  }
  .social-icons-container {
    text-align: center;
  }
  .copy-para {
    text-align: center;
  }
  .copy-input {
    width: 100%;
    height: 4rem;
    padding: 0 1rem 0 4rem;
    line-height: 4rem;

    border: 3px solid #4a6d83;
  }
  .copy-input-container {
    position: relative;
    &.copy-ok {
      background-color: #d9fcd9;
    }
  }

  .copy {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    z-index: 1;
    background-image: url("../../../../../assets/icons/copy.svg");
    background-size: 22px auto;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.6;
    cursor: pointer;
    background-color: #000;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';