.statistics-container-prototype {
  .export-btn {
    background-color: var(--main-layout-text-color-2);
    margin-left: 0.5rem;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  // overflow-y: scroll;
  margin-bottom: 5rem;
  .heading {
    background-color: #173b50;
    border-bottom: 1px solid #96a7b0;
    padding: 1rem 0;
    color: #96a7b0;
    font-size: 2.2rem;
    text-align: center;
    font-weight: lighter;
    margin: 0;
  }

  .statistics-content {
    margin: 0 auto 1.5rem auto;
    background: #24668d;
    border-bottom: 1px solid #96a7b0;
    padding: 1rem 0;
    min-height: 85vh;

    .datepicker-container {
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      .react-datepicker-wrapper {
        width: initial;
      }
    }

    .broadcast-container {
      text-align: center;
    }

    .broadcast-container-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 1rem;
    }

    .heading-container {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .ant-image {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      .copy-image {
        width: 24px;
        display: block;
        &:active {
          transform: scale(1.1);
          opacity: 0.75;
        }
      }
    }

    .item-container {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .p {
      text-align: center;
      color: white;
      font-size: 2rem;
      margin-bottom: 1rem;
      grid-column: 2;
    }

    .broadcast-title {
      text-align: center;
      color: #fff;
      font-weight: normal;
      margin-bottom: 0;
      font-size: 3.2rem;
      grid-column: 2;
      display: grid;
    }

    .broadcast-para {
      color: white;
      margin: 2rem 0px;
      text-align: center;
      font-size: 2rem;
    }
  }

  .group-by-radio-container {
    margin: 1rem;
    .group-playlist-stats {
      span {
        color: #fff;
      }
    }
    .ant-radio-group {
      .ant-space {
        display: flex;
        flex-direction: inherit;
      }
    }
    .group-playlist-label {
      color: #fff;
      display: block;
      font-size: 2rem;
    }
  }
}

@media only screen and (max-width: 350px) {
  .statistics-container-prototype {
    .statistics-content {
      .broadcast-container-content {
        display: block;
        padding: 0 0.5rem;
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';