.temp-down-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #162d55;
  .content {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #f6f6f6;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    height: 100vh;
  }
  .content .title {
    margin-top: 1em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.23;
    font-size: 38px;
  }

  .content p {
    font-size: 14px;
    text-align: center;
  }

  h1 {
    color: #fff;
  }

  .content .a {
    fill: #fff;
  }

  .content .b {
    fill: #ef8508;
  }
}

@media (max-width: 500px) {
  .temp-down-page {
    .content svg {
      width: 100% !important;
    }
    .content .title {
      font-size: 20px;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';