.back-to-login-container {
    // display: grid;
    place-items: center;
    // height: 45vh;

    .ant-typography {
        text-align: center;
        letter-spacing: 0.065rem;
        color: var(--main-layout-text-color);
        font-size: 1.8rem;
        margin-bottom: 0;
        align-self: end;
    }
    .login-btn {
        margin: 2rem auto;
        width: 17.4rem;
        min-width: 4rem;
        min-height: 4rem;
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        background: transparent;
        padding: 0.6rem 2.3rem 1rem 2.3rem;
        color: var(--main-layout-text-color-2);
        border-color: var(--main-layout-text-color-2);
        text-transform: capitalize;
        box-shadow: 0rem 0rem 0rem 0.1rem;

        &:disabled {
            background-color: transparent;
            color: var(--main-layout-text-color-2);
            border-color: var(--main-layout-text-color-2);
            opacity: 0.5;
        }


        .logout-image {
            min-width: 2rem;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .back-to-login-container {
        .login-btn {
            min-width: 10.4rem;
            font-size: 1.5rem;
            padding: 0.2rem;
            max-width: 10rem;
            .logout-image {
                min-width: 1rem;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';