@import "@styles/variables.less";

.arrange-mode {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  // border: 1px solid #eee;
  border-radius: 1rem;
  padding: 3px;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';