.cookies-info {
    width: 60%;
    margin: 2rem auto;
    padding-bottom: 2rem;
    .go-back {
        display: inline-block;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 660px) {
    .cookies-info {
        width: 95%;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';