.auto-complete-dropdown-channels {
    background-color: #1d3c71;

    .rc-virtual-list-holder-inner,
    .ant-select-dropdown {
        background-color: #1d3c71;
    }
    .ant-select-item-option-content {
        color: #eee;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #162d55;
    }
    .ant-select-item-option-active {
        background-color: #162d55 !important;
    }
}

.search-channel-container {
    margin: 1rem 0;
    border-top: 0.8rem solid #162d55;
    border-bottom: 0.8rem solid #162d55;
    padding: 2.4rem 0;

    &.no-borders {
        border-top: none;
        border-bottom: none;
    }

    .search-channels-input {
        width: 80%;
        margin: auto;
        display: block;
        height: 4.8rem;
        max-width: 34.8rem;
        border-radius: 0.8rem;
        color: var(--main-layout-text-color);
        padding-left: 5rem;
        background: url("../../../../../assets/icons/search-icon.svg") no-repeat
            scroll 0.6rem 1.3rem #1d3c71;

        &.playlist-search-input-mobile {
            background: url("../../../../../assets/icons/search-icon.svg")
                no-repeat scroll 0.8rem 0.6rem #1d3c71;
        }

        &::placeholder {
            color: #eee;
            opacity: 0.4;
            letter-spacing: 0.009rem;
        }

        &:-ms-input-placeholder {
            color: #eee;
            opacity: 0.4;
            letter-spacing: 0.009rem;
        }

        &::-ms-input-placeholder {
            color: #eee;
            opacity: 0.4;
            letter-spacing: 0.009rem;
        }
    }

    .auto-complete-container {
        text-align: center;
        display: flex;
        justify-content: center;

        .ant-select-auto-complete {
            width: 100%;
        }

        .auto-complete-inner {
            width: 80%;
            position: relative;

            .clear-input-icon {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                width: fit-content;
                right: 1rem;
                height: 100%;
                background: #162d55;
                padding-left: 1rem;
                cursor: pointer;
            }
        }
    }

    .search-channels-input-mobile {
        max-width: none;
        margin: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .search-channel-container {
        .auto-complete-container {
            display: flex;
            justify-content: center;
            .ant-select-auto-complete {
                width: 100%;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';