.more-buttons {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: flex-end;

  position: absolute;
  bottom: 75%;
  left: -5%;
  z-index: 1;

  button {
    padding: 0.5rem 1rem;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11rem;
    width: 100%;

    outline: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);

    &.go-to {
      background-color: #ef8508;
    }

    &.more-info {
      background-color: #092342;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';