.context-menu-prototype {
  @arrow-dim: 1rem;

  z-index: 9999;
  background-color: black;
  color: #fff;
  min-width: 12rem;
  position: fixed;
  margin: 0;
  left: 0;
  animation: openFromBotton 0.3s ease;
  transform: translateY(-@arrow-dim);
  border-radius: 0.5rem;

  .arrow-down {
    content: "";
    width: 0px;
    height: 0px;
    border-top: @arrow-dim solid black;
    border-right: @arrow-dim solid transparent;
    border-bottom: @arrow-dim solid transparent;
    border-left: @arrow-dim solid transparent;
    position: absolute;
    left: calc(50% - @arrow-dim);
    top: 100%;
  }
  .arrow-up {
    .arrow-down();
    transform: rotate(180deg);
    top: -9%;
  }

  .context-menu-list {
    list-style-type: none;
    cursor: pointer;
    font-size: 15px;
    color: #eee;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0;
    min-width: 10rem;

    li {
      display: flex;
      cursor: pointer;
      font-size: 15px;
      color: #eee;
      border-radius: 5px;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 2rem;
      font-size: 1.7rem;
      width: 100%;

      span {
        white-space: nowrap;
      }
    }
  }
  .ant-image {
    display: block;
    margin-right: 1.3rem;
    width: auto;
  }
  .icon-img {
    width: 1.8rem;
    min-height: auto !important;
  }
  .copy-img {
    filter: invert(1);
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

@keyframes openFromBotton {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';