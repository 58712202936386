.auth-heading-container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;

    .ant-image {
        img {
            margin-bottom: 1rem;
        }
    }

    h2 {
        margin-bottom: 0.5rem;
        color: @text-color-dark;
        line-height: 1.25;
        font-weight: 400;
    }
    .register-para {
        color: @text-color-dark;
        width: 80%;
        margin: auto;
        text-align: center;
        font-size: 1.6rem;
        letter-spacing: 0.015rem;
    }
}

@media (min-width: 1100px) {
    .auth-heading-container {
        .ant-image {
            img {
                width: 356px;
            }
        }
    }
}

@media only screen and (min-width: 798px) {
    .auth-heading-container {
        margin-top: 10rem;
    }
}

@media only screen and (max-width: 992px) {
    .auth-heading-container {
        h2 {
            font-size: 2.4rem;
        }
        .ant-image img {
            width: 200px;
        }
    }
}

@media only screen and (max-height: 760px) {
    .auth-heading-container {
        .hide-logo {
            display: none;
        }

        .auth-headings-container {
            margin-bottom: 5rem;
        }
    }
}

@media only screen and (max-height: 650px) {
    .auth-heading-container {
        .auth-headings-container {
            margin: 2rem 0;
        }
    }

    .auth-heading-container .ant-image img {
        width: 180px;
    }
    .auth-heading-container h2 {
        font-size: 2.2rem;
    }
}

@media only screen and (max-height: 580px) {
    .auth-heading-container {
        margin-top: 5rem;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';