@import "@styles/variables.less";

.header-prototype {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr 1fr;
  background-color: #173b50;
  border-bottom: 1px solid #96a7b0;
  color: #96a7b0;
  font-family: "Helvetica Neue Light", "Lucida Grande", "Calibri", "Arial",
    sans-serif;
  padding: 10px 0;
  z-index: 20;
  width: 100%;
  height: @header-height;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  width: 100%;
  &.fixed {
    position: fixed;
  }

  .title {
    color: #96a7b0;
    text-transform: uppercase;
    font-size: 16px;
    padding-left: 10px;
    display: inline-block;
    line-height: 1;
    // max-width: 20rem;
    display: inline-block;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    position: absolute;
  }

  .pane-mobile {
    margin-top: 25px;
    left: 0;
  }

  .ant-image-img {
    border-radius: 100px;
  }

  .follow-container {
    justify-self: end;
    color: #fff;

    button:disabled {
      color: #fff;
    }
  }

  .ant-btn-prototype {
    span {
      padding: 0.1rem 0.3rem;
    }

    &.btn-sm-white {
      background-color: transparent;
      width: fit-content;
      padding: 0;
      min-width: 6.5rem;
      min-height: initial;
      border-color: white;
      border-radius: 5px;
    }
  }

  .icon-container {
    justify-self: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    top: 0.5rem;
    // background-coloxr: #4b6d83;
    border: 2px solid white;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #173b50;
    transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
    opacity: 1;
    z-index: 18;

    .uncropped-img {
      width: 60px;
      height: 15px;
      margin-top: 15px;
    }

    .ant-image .ant-image-img {
      // border-radius: 3px;
      // background-color: transparent;
    }

    .ant-image {
      height: 99%;
      // padding: 0.6rem;

      img {
        max-height: 5rem;
        object-fit: cover;
        max-width: 100%;
      }
    }
  }
  .channel-menu-prototype {
    justify-self: center;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';