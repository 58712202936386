.auth-heading-container {
    .auth-headings-container {
        margin: 1rem 0;
        margin-top: 3rem;
        margin-bottom: 11.3rem;
        .login-sub-text {
            &.ant-typography {
                margin-top: 0.5rem;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';