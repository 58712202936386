.ant-form-prototype {
  width: 100%;

  span {
    color: white;
    font-weight: 400;
    cursor: pointer;
  }
  .ant-form-item:last-child {
    margin-bottom: 2rem;
}

  .ant-form-item-control-input {
    border: none;
    width: 100%;
  }
}

.paragraph-prototype {
  color: #5da3ef;
  font-size: 2rem;
  text-align: center;
  transition: all .3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #91caff;
  }

}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';