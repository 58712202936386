.homepage-container {
  background: #2c4c7b;
  text-align: center;
  color: white;
  font-family: "Montserrat", sans-serif;
  //   padding: 1rem;
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .home-content-img {
    width: 100%;
  }

  &:empty {
    display: none;
  }
}

.homepage-container h3 {
  color: white;
  font-weight: 200;
  width: 30rem;
  margin: auto;
}

.homepage-container h4 {
  color: white;
  font-weight: 100;
  line-height: 1.2;
  //   margin-bottom: 2rem;
  padding: 2rem;
  padding-top: 0;
  margin-top: 0;
}

.ant-image {
  display: flex;
  justify-content: center;
  width: 100%;
  // padding-top: 2rem;
}

.choice-logo {
  width: 100%;
  max-width: 40rem;
  height: auto;
  padding: 2rem;

  &.white-logo {
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .choice-logo {
    width: 40rem;
  }
}
@media only screen and (max-width: 768px) {
  .choice-logo {
    width: 95%;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';