.not-found-page {
    background-color: var(--main-layout-bg-2);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .error-content {
        margin: 0 .5rem;
        .ant-typography {
            color: var(--main-layout-text-color);
            letter-spacing: 0.065rem;
            text-align: center;
        }
        .title {
            text-transform: uppercase;
            font-weight: 400;
        }
    }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';