.input-prototype {
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.6rem 1rem;
  text-align: center;
  outline: none;
  border-radius: 4px;
  display: inline-block;
  font-size: 3rem;
  width: 100%;

  &.transparent {
    background: transparent;
    border-color: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
    transition: all .2s linear;
    color: white;
    padding: 0 .75rem;
    margin-top: 3px;
    max-height: 35px;

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(0, 123, 255, 0.14);
    }

  }

}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';