.slick-arrow img {
  height: 68px;
  width: 68px;
}

.active {
  background-color: rgb(87, 125, 151); border-radius: 1rem; padding: 1rem 0.2rem;height: 90px;
}

.main-layout {
  &.two-dimentions {
    grid-template-columns: 8rem 22.5% auto;
  }
  display: grid;
  grid-template-columns: 8rem 22.5% 47.5% auto;
  height: 100vh;
  color: var(--main-layout-text-color);

  ::-webkit-scrollbar {
    width: 0px;
  }

  .menu {
    padding: 0 1.2rem;
  }

  .menu,
  .second-dimension {
    background-color: var(--main-layout-bg-2);
  }

  .first-dimension {
    min-height: 100vh;
    height: 100%;
  }

  .first-dimension,
  .third-dimension {
    background-color: var(--main-layout-bg-1);
  }

  .first-dimension,
  .second-dimension,
  .third-dimension {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .ant-empty-description {
    color: var(--main-layout-text-color);
  }
}

.main-mobile-layout {
  background-color: var(--main-layout-bg-2);
  color: var(--main-layout-text-color);
  padding: 1rem 1.6rem;
  min-height: 85vh;

  .ant-empty-description {
    color: var(--main-layout-text-color);
  }
}

.main-mobile-layout-fluid {
  padding: 1rem 0;
}

// prototype mobile layout version

.mobile-layout-prototype {
  background-color: #3990c3;
  min-height: 95vh;
  // overflow-y: scroll;

  &.bg-black {
    background-color: #000;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';