.videos-header-mobile {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    align-items: center;
    width: 90%;
    margin: auto;

    .sort-container {
        display: inline-block;
        margin-right: 0;
    }
    .icons-container {
        justify-self: end;
        align-self: flex-start;
        margin-top: 0.5rem;

        svg {
            margin: 0 0.8rem;
        }
    }
    .popup-toggle {
        margin-right: 0;
        padding-right: 0;
    }
    .title {
        color: var(--main-layout-text-color);
        margin-bottom: 0;
    }

    .sub-text {
        color: var(--main-layout-color-1);
    }

    .plus-icon-svg,
    .share-icon-svg,
    .pen-icon-svg,
    .sort-container {
        &:hover {
            cursor: pointer;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';