@import "@styles/mixins.less";

.ant-list {
  &.ant-list-split {
    &.search-subbar-container {
      display: flex;
      align-items: flex-start;
      height: 10.5rem;
      width: 100.1%;

      .ant-spin-nested-loading {
        width: 100%;
        .ant-spin-container {
          justify-content: flex-start;

          .ant-list {
            &.ant-list-split {
              &.search-list-prototype {
                display: flex;
              }
            }
          }
        }
      }
    }

    &.search-list-prototype {
      // overflow-y: auto;
      // .custom-scrollbar();
      height: 10.5rem;
      display: flex;
      align-items: center;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';