.modal-basic-prototype {

    .accept {
        margin-right: 10px;
    }

    &.simple-popup {
        padding: 20px;
 }

 .ant-modal-footer {
    display: flex;
    justify-content: center;

    .custom-footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            margin-top: 20px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
 }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';