@import "@styles/mixins.less";

.tablet-prototype-container {
  height: 100vh;
  background-color: #073a77;
  position: relative;
  user-select: none;

  // .indiana-scroll-container--hide-scrollbars {
  //   overflow: scroll !important;
  // }

  .ant-modal-mask {
    position: absolute;
    left: 0;
  }
  .ant-modal-wrap {
    position: absolute;
  }

  .ant-modal-header {
    margin-top: 4rem;
  }

  .ant-modal-content {
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    overflow-y: auto;
    .custom-scrollbar();
  }

  .alert-modal-prototype {
    width: 90%;
    .ant-modal-content {
      width: 90%;
    }
  }

  .scroll-container {
    height: calc(100vh - 20px);
  }

  // .header-prototype {
  //   position: absolute;
  //   top: 0;
  // }

  .sub-bar-prototype {
    position: absolute;
    overflow-y: hidden;
    -webkit-user-select: none;
    user-select: none;
    padding: 0;
    .ant-list,
    .ant-spin-container {
      min-height: 10rem;
    }
    .sub-bar-prototype__content {
      min-height: 8rem;
    }
  }

  .mobile-alert-prototype {
    position: fixed;
    bottom: 50px;
    left: 10%;
    width: 30%;
    border: 1px solid #21272b;
    display: block;
    padding: 20px;
    font-family: sans-serif;
    font-size: 15px;
    line-height: 21px;
    .ant-typography {
      color: #7c929e;
    }
  }
  .mobile-playlists-prototype,
  .statistics-container-prototype {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .video-container .mobile-tiers {
    min-height: 85vh;
  }

  .tablet-prototype {
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 52%;
    right: 19rem;
    // background: black;
    border-radius: 30px;
    box-shadow: 0 0 17px black;
    box-sizing: inherit;
    // overflow: hidden;
    width: initial !important;
    border-style: solid;
    border-color: black;
    border-width: 4rem 0.8rem;
    outline: 1px solid rgba(255, 255, 255, 0.3);
    max-height: 100%;

    &::after {
      content: "";
      width: 101%;
      height: 4rem;
      background-color: black;
      position: absolute;
      bottom: -4rem;
      right: -1px;
      border-radius: 0 0 3rem 3rem;
      z-index: 500;
    }

    &__content {
      .custom-scrollbar();
      overflow: auto;
      height: 100%;
      max-height: 85vh;
    }

    .ant-modal-wrap {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      scrollbar-width: 5px;
    }
  }
  .mobile-navigation-prototype {
    user-select: none;
    -webkit-user-select: none;
    position: absolute;
    width: 100%;
    z-index: 30;

    .mobile-navigation-wrapper {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .tablet-prototype-container {
    .tablet-prototype {
      right: 10rem;
      left: 49%;
    }
    .alert-modal-prototype {
      .ant-modal-content {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .tablet-prototype-container {
    .tablet-prototype {
      right: 2rem;
      .alert-modal-prototype {
        .ant-modal-content {
          width: 90%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .tablet-prototype-container {
    .mobile-alert-prototype {
      left: 10%;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';