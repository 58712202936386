.privacy-container-prototype {
  .privacy-policy-ol {
    padding: 0;
  }
  ol {
    padding-left: 6rem;
  }
  .privacy-policy-tertiary-title {
    font-size: 17px;
    margin-left: 15px;
    color: #1c3c71;
    font-weight: bold;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';