.following-list-item-container {
    width: 85%;
    margin: auto;
    position: relative;

    .ant-image {
        width: 100%;
        height: 100%;
        &-img {
            height: 100%;
        }
    }

    .ant-list-item {
        display: grid;
        grid-template-columns: auto 4fr max-content;
        grid-column-gap: 2.4rem;

        .icon-container {
            .play-buttons-menu {
                border-radius: 100px;
            }
        }

        &:hover {
            background-color: #1d3c71;
            border-radius: 1rem;
            transition: all 0.3s;
            cursor: pointer;
        }
    }

    .playlist-statistic-container {
        display: grid;
        grid-template-columns: max-content 2rem max-content;
        align-items: center;
        justify-items: center;

        .para {
            margin-bottom: 0;
        }
    }

    .title {
        color: var(--main-layout-text-color);
        font-size: 2rem;
        text-transform: capitalize;
        overflow-wrap: anywhere;
    }
    .para {
        color: var(--main-layout-color-1);
        font-size: 1.4rem;
    }
    .icon-container {
        background-color: var(--main-layout-bg-2);
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 992px) {
    .following-list-item-container {
        .ant-list-item {
            grid-column-gap: 1.4rem;

            .title {
                font-size: 1.8rem;
            }
            .para {
                font-size: 1.2rem;
            }

            .icon-container {
                width: 3.8rem;
                height: 3.8rem;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';