.wallet-container-prototype {
  background-color: #0f1e39;
  width: 100%;
  font-family: sans-serif;
  padding-top: 10px;
  color: white;
  margin-bottom: 5rem;
  min-height: 100vh;

  .title {
    margin-top: 2rem;
    text-align: center;
    color: white;
    font-size: 1.8rem;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';