@import "~antd/dist/antd.less";
@import "./styles/variables.less";
@import "@styles/mixins.less";

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  // user-select: none;
  // -webkit-user-select: none; /* disable selection/Copy of UIWebView */
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem === 10px */
}

body {
  font-weight: 400;
  line-height: 1.6;
  width: 100% !important;
  .custom-scrollbar();
  background-color: #3990c3;
  // font-family: "Helvetica Neue Light", "Lucida Grande", "Calibri", "Arial",
  //   sans-serif;
}
button {
  font-family: sans-serif;
}
h4,
h2 {
  font-weight: normal;
  letter-spacing: 0.066rem;
}

.sub-text {
  color: #a5b1c6;
}

.App.dark-theme {
  //colors
  --main-layout-bg-1: #0f1e39;
  --main-layout-bg-2: #162d55;
  --main-layout-text-color: #f6f6f6;
  --main-layout-text-color-2: #f08507;
  --main-layout-color-1: #a5b1c6;
  --color-green: #10b981;
  --color-red: #ee2f5d;
}
.App.light-theme {
  //colors
  --main-layout-bg-1: #ccc;
  --main-layout-bg-2: #ddd;
  --main-layout-text-color: #000;
}

.blue-logo,
.white-logo {
  margin-bottom: 4.8rem;
}

.otp-container {
  .otp-container-style {
    align-items: center;
    justify-content: center;
  }
  .otp-style {
    background-color: rgb(210, 216, 227);
    border: none;
    border-radius: 100px;
    font-size: 3.4rem;
    outline: none;
    color: @text-color-dark;
    font-family: Roboto;
    min-width: 5.2rem;
    justify-content: center;
  }
}
.email-label {
  margin-top: -2.6rem;
  letter-spacing: 0.026rem;
  color: @text-color-dark;
  line-height: 1.25;
  font-weight: 500;
}

.register-row {
  min-height: 100vh;
}

.next-btn-container {
  button {
    display: block;
    margin: 3rem auto;
    width: 90%;
  }
}

.diemensions-header {
  &.third-diemension-header {
    display: grid;
    place-items: center;
    font-size: 2rem;
    letter-spacing: 0.252rem;
    text-transform: capitalize;

    .ant-typography {
      color: var(--main-layout-text-color);
      margin-bottom: 0;
      display: block;
      text-align: center;
    }
  }
}

.error-text {
  &.ant-typography {
    color: var(--main-layout-text-color);
    text-align: center;
    font-weight: normal;
    margin-top: 0.5rem;
  }
}

.is-dragging {
  background-color: #1d3c71;
  border-radius: 1rem;
}

.slick-arrow {
  width: 75px !important;
  height: 45px !important;
  z-index: 10 !important;
  margin: 0 1rem;
  transform: translateY(-10rem) !important;
}

button.slick-arrow {
  transform: translateY(-10rem) !important;
}

.slick-arrow.slick-prev {
  left: 1%;
}

.slick-arrow.slick-next {
  right: 1%;
}

.slick-prev:before,
.slick-next:before {
  font-size: 4rem !important;
}

.slick-arrow.slick-next:before {
  content: none;
}

.slick-arrow.slick-prev:before {
  content: none;
}

.slick-disabled img {
  opacity: 0.25;
}

@media only screen and (max-width: 1200px) {
  .choice-logo {
    width: 30.6rem;
  }
}
@media only screen and (max-width: 992px) {
  .auth-row,
  .register-row {
    .ant-btn {
      text-transform: uppercase;
      min-width: 15rem;
      min-height: 4rem;
      font-size: 1.5rem;

      &:hover {
        min-width: 20rem;
        transition: all @animation-duration-slow;
      }
    }
  }
  .choice-logo {
    width: 22.6rem;
  }
}



// keyframes //

@keyframes liftUp {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(10rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
    display: block;
  }
}

@keyframes liftUpShort {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
    display: block;
  }
}

@keyframes liftDown {
  0% {
    opacity: 1;
    transform: translateY(0rem);
  }
  100% {
    opacity: 0;
    transform: translateY(10rem);
    display: none;
  }
}

@keyframes liftUpRight {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-5rem) translateY(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes showSlow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';