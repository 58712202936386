.confirm-adult-content-modal {
  background-color: rgba(11, 90, 142);
  box-shadow: none;
  margin: 0 auto;
  max-width: 400px;

  .ant-modal-header {
    background-color: rgba(11, 90, 142);
    border-radius: 0;
    // padding-top: 35px;

    .ant-modal-title {
      color: white;
      margin-top: 50px;
      letter-spacing: normal;
      font-size: 1.75rem;
    }
  }
  .ant-modal-body {
    background-color: rgba(11, 90, 142);
    border: none;
  }
  .otp-container {
    .otp-container-style {
      input {
        background-color: #fff;
        width: 5rem;
        border: 3px solid #333;
        border-radius: 100rem;
        height: 5rem;
        padding: 1rem;
        margin: 1rem;
      }
    }
  }
  .ant-modal-content {
    background-color: rgba(11, 90, 142);
  }
  .ant-modal-footer {
    background-color: rgba(11, 90, 142);
    button {
      width: 100%;
      color: white;
      background-color: #f08605;
      border: none;
      border-radius: 4px;
    }
  }

  .ant-modal-content {
    box-shadow: none;
    // width: 80%;
  }

  .ant-modal-close-x {
    position: absolute;
    top: 10px;
    right: 5px;
    height: 30px;
    width: 30px;
    padding: 5px;
    border: 5px solid #333;
    border-radius: 50%;
    background-color: #333;
    // background-image: url(../_img/icons/close.svg);
    background-position: center;
    background-size: contain;
    cursor: pointer;

    svg {
      position: absolute;
      top: 10%;
      left: 10%;
      color: white;
    }
  }

  .otp-container {
    .otp-container-style {
      align-items: center;
      justify-content: center;
      border-radius: 0;
    }
    .otp-style {
      background-color: rgba(11, 90, 142);
      border-bottom: 1px solid #f08605;
      border-radius: 0;
      font-size: 3.4rem;
      outline: none;
      color: @text-color-dark;
      font-family: Roboto;
      min-width: 5.2rem;
      justify-content: center;
    }
  }
}

@media (max-width: 1000px) {
  .confirm-adult-content-modal {
    max-width: 80%;

    .ant-modal-content {
      width: 90%;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';