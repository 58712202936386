.alert-modal-prototype {
  width: 90%;
  .warning-img {
    width: 5rem;
  }

  .ant-modal-body {
    padding-top: 4rem;
  }
  .ant-modal-content {
    width: 90%;
  }

  .ant-modal-body div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    .ant-image {
      display: inline-block;
      align-self: start;
      flex-shrink: 1;
      flex-basis: 5rem;
    }

    .ant-typography {
      flex-grow: 1;
      font-size: 2.1rem;
      font-family: "Helvetica Neue Light", "Lucida Grande", "Calibri", "Arial",
        sans-serif;
      line-height: 1.2;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';