.team-add-playlist-container {
    margin-top: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    align-items: center;
    width: 80%;
    margin: auto;

    button {
      background-color: #f08605;
      border: unset;
      color: white;
      padding: 6px 3rem;
      border-radius: 4px;
      font-size: 16px;
  }

  .ant-checkbox-wrapper span {
    color: white;
  }

  }
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';