.mobile-following-container {
    .suggestions {
        min-height: 45vh;
    }
    .following-video-scroll {
        display: flex;
        overflow-x: scroll;
        width: 90%;
        margin: auto;
    }

    .following-list-item-container {
        .icon-container {
            background-color: #1d3c71;
            height: 4.8rem;
            width: 4.8rem;
        }
    }

    .following-video-scroll {
        display: flex;
        overflow-x: scroll;
        width: 90%;
        margin: auto;
    }

    .error-contaienr {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
    }
}
@media only screen and (max-width: 768px) {
    .mobile-following-container {
        .following-list-item-container {
            min-height: 8rem;
        }
        .search-channel-container {
            width: 85%;
            margin: auto;
        }
        .following-search {
            padding: 1.6rem 0;
        }
        .search-borders {
            border-bottom: 0.4rem solid #0f1e39;
            border-top: 0.4rem solid #0f1e39;
        }
    }
}
@media only screen and (max-width: 600px) {
    .mobile-following-container {
        .following-list-item-container {
            width: 100%;
        }
        .search-channel-container {
            width: 100%;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';