@import "@styles/mixins.less";

.wallet-tabs-container {
  .ant-tabs {
    .ant-tabs-nav-wrap {
      display: block;
      width: 98%;
      margin: auto;
    }

    .ant-tabs-content {
      width: 98%;
      margin: auto;
      color: white;
      // height: 35vh;

      .ant-tabs-tabpane {
        overflow-x: scroll;
        overflow-y: hidden;
        // height: 50vh;
        .custom-scrollbar();
      }

      .ant-empty-description {
        color: #fff;
      }
    }
    .ant-tabs-nav-list {
      width: 100%;
      position: static;
      display: flex;
      justify-content: space-between;
    }
    .ant-tabs-tab {
      border: 1px solid white;
      text-align: center;
      padding: 1rem;
      font-size: 18px;
      border: 1px solid white;
      border-radius: 5px;
      margin-right: 0;
      width: 30%;
      text-align: center;
      display: block;
    }
    .ant-tabs-tab-btn {
      color: white;
      text-align: center;
    }
    .ant-tabs-nav {
      display: block;
    }
    .ant-tabs-tab-active {
      background-color: #1d3c71;
    }
    .ant-tabs-nav::before {
      border: none;
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';