@import "@styles/mixins.less";

.edit-playlist-video-modal-prototype {
  top: 2%;
  .ant-modal-body {
    border-top: unset;
    max-height: 65vh;
    overflow-y: auto;

    .custom-scrollbar();

    input {
      font-size: 16px;
    }
  }

  .form-control {
    &.or {
      font-size: 2rem;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  .edit-video-btn{
    position: relative;
  }

  .video-upload {
    border: none !important;
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::before {
      // content: "Upload channel image";
      content: attr(data-content);
      width: -webkit-fill-available;
      display: inline-block;
      background: #f08605;
      border: 1px solid #999;
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 10pt;
      color: #fff;
      font: inherit;
      text-align: center;
      margin-bottom: 0rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:disabled {
      opacity: 0.8;
    }
  }

  .img-delete-btn {
    padding: 1rem 1rem;
    border-radius: 4px;
    text-align: center;
    color: white;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #dc3545;
  }

  .ant-modal-content,
  .ant-modal-header {
    background-color: rgba(11, 90, 142);
  }
  .ant-modal-header {
    padding-top: 1px;
    padding-bottom: 2.5rem;
  }
  .ant-modal-title {
    margin-top: 10%;
    font-weight: 600;
    color: white;
    font-size: 2.5rem;
  }
  .ant-modal-content {
    border-radius: unset;
    width: 90%;
    padding: 0 1rem;
  }
  .ant-modal-body > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  .ant-modal-body > div > label {
    color: white;
    font-family: sans-serif;
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
    font-weight: 500;
  }
  .ant-modal-body > div > input {
    border-radius: 1rem;
    border: 0.2rem solid rgba(0, 0, 0, 0.2);
    padding: 0.3rem 1rem;
  }
  .ant-modal-body > div > select {
    border-radius: 1rem;
    border: 0.2rem solid rgba(0, 0, 0, 0.2);
    padding: 0.3rem 1rem;
  }
  .ant-modal-body > div > select :focus {
    border: none;
  }
  .ant-modal-footer {
    display: flex;
    // justify-content: center;
    margin-left: 1rem;
  }
  .ant-modal-footer > button {
    background-color: #f08605;
    border: unset;
    color: white;
    padding: 0.6rem 3rem;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    position: relative;
  }
  .btn-spinner {
    left: 85%;
  }
  .edit-video-btn {
    padding-top: 1rem;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .edit-video-footer {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 1rem;

    button {
      padding: 1rem 2.5rem;
      border-radius: 4px;
      text-align: center;
      color: white;
      font-size: 16px;
      cursor: pointer;
      border: none;
      outline: none;
    }

    .edit-video-btn {
      background-color: #f08605;
    }

    .remove-video-btn {
      background-color: #dc3545;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .edit-playlist-video-modal-prototype {
    .ant-modal-content {
      border-radius: unset;
      width: 90%;
      padding: 0 1rem;
      overflow: auto;
      max-height: 85vh;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';