.update-profile-form-prototype {
  width: 100%;
  margin: 0 0 20px 0;
  padding: 7px;
  margin-top: 5rem;

  .profile-image {
    width: 7.3rem;
    height: 7.3rem;
    display: flex;
    border-radius: 100rem;
  }
  .profile-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    margin-bottom: 1rem;
    .img-container {
      z-index: 400;
      background-color: #eee;
      border-radius: 100rem;
    }
    .delete {
      position: absolute;
      color: var(--color-red);
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      text-transform: capitalize;
      z-index: 500;
      top: -10%;
      left: 52%;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 100rem;
    }
  }

  .update-profile-logo-container {
    // margin-top: 22rem;
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-content: center;

    .ant-image {
      display: flex;
    }

    .update-profile-logo-img {
      width: 8rem;
      display: flex;
    }
  }

  input {
    border: none;
  }

  .upload-image-from-item {
    width: 100%;
    margin: auto;
    .ant-form-item-control {
      align-items: center;
    }
    label {
      padding: 0 1rem 0 0;
    }
    .input-type-file {
      width: 18rem;
    }
  }

  .ant-select {
    margin: 1rem 0;
    padding: 0.7rem;
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    font-size: 2.2rem;
  }

  .ant-select-selection-placeholder {
    color: white;
  }

  .react-datepicker-wrapper {
    width: 100%;
    // color:red;
    margin: 1rem 0;
    padding: 0.7rem;
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    font-size: 2.2rem;
  }

  .gender-dropdown {
    width: 100%;
    // color:red;
    margin: 1rem 0;
    padding: 0.7rem;
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    font-size: 2.2rem;
  }

  .email-checkbox,
  .profile-find-checkbox,
  .date-label {
    display: block;
    text-align: center;
    color: white;
  }
}

// @media only screen and (max-width: 330px) {
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//       &.ex-sm {
//         margin-top: 37rem;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 360px) {
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//       &.sm {
//         margin-top: 37rem !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 400px) {
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//       &.md {
//         margin-top: 20rem;
//       }
//     }
//   }
// }

// @media only screen and (min-width: 500px) and (max-width: 850px) {
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//         margin-top: 20rem;
//     }
//   }
// }

// @media only screen and (min-width: 900px) and (max-width: 1000px){
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//         margin-top: 20rem;
//     }
//   }
// }

// . @media only screen and (max-width: 600px) and (min-width: 400px) {...}

// @media screen and (max-width: 410px) {
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//       margin-top: 10rem;
//     }
//   }
// }

// @media screen and (max-width: 412px) {
//   .update-profile-form-prototype {
//     .update-profile-logo-container {
//       margin-top: 4rem;
//     }
//   }
// }

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';