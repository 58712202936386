.settings-page {
    .first-dimension {
        text-align: center;
        .log-out-btn {
            min-width: 13rem;
            font-size: 1.6rem;

            &:hover {
                min-width: 13rem;
            }
        }
    }
}

.main-layout.settings-page.two-dimention {
    grid-template-columns: 8rem 22.5% auto 0;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';