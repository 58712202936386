@import "@styles/mixins.less";

.teams-container-prototype {
  padding-bottom: 3rem;
  height: 100vh - 10px;

  .heading {
    background-color: #173b50;
    border-bottom: 1px solid #96a7b0;
    padding: 1rem 0;
    color: #96a7b0;
    font-size: 2.2rem;
    text-align: center;
    font-weight: lighter;
    margin: 0;
  }

  .leave-btn-cnt {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    button {
      color: #fff;
      width: 150px;
      background: #f08507;
      border: none;
      border-radius: 5px;
      padding: 5px 0px;
      font-size: 16px;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .teams-content {
    margin: 0 auto 0 auto;
    background: #24668d;
    border-bottom: 1px solid #96a7b0;
    min-height: 100%;
    min-height: 85vh;

    .teams-heading {
      color: #fff;
      padding: 5rem 0;
      display: flex;
      width: 80%;
      margin: 0 auto;

      img {
        width: 150px;
        margin: 0 auto;
      }
    }

    .teams-list {
      display: flex;
      flex-direction: column;
      border-radius: 12px 12px 0 0;

      .teams-list-groups {
        height: 290px;
        overflow-y: auto;
        border: 1px solid #fff;
        border-radius: 12px;
        width: 80%;
        margin: 2rem auto 0 auto;
        .custom-scrollbar();
      }

      .teams-list-row {
        color: #fff;
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 1.5fr 1fr;
        text-align: center;
        margin-top: 1rem;
        padding: 1.5rem;
        width: 100%;

        img {
          width: 30px;
          height: 30px;
          margin-left: 5.5rem;
        }

        &.add-item {
          border: 1px solid #fff;
          width: 80%;
          margin: auto;
          border-radius: 12px;

          p {
            padding: 0;
            margin: 0;
          }

          img {
            margin-left: 5rem;
          }
        }

        .teams-list-item {
          position: relative;
          display: inline-flex;
          vertical-align: top;
          margin-left: -0.3rem;
          // left: 0;

          p {
            align-items: center;
            display: flex;
            align-content: center;
            width: 70px;
            padding: 0;
            margin: 0;
          }

          .teams-info {
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding-left: 1.5rem;
            padding-top: 0.3rem;
          }

          // .admin-icon {
          //   margin-top: 30px;
          //   // padding-right: 5px;
          //   font-size: 20px;
          //   color: #f08507;
          // }

          .ant-image {
            display: inline-block;
            height: 50px;
            width: 50px;
            vertical-align: top;
            padding-left: 5px;

            p {
              display: inline-block;
            }

            img {
              display: inline-block;
              vertical-align: top;
              height: 50px;
              width: 50px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';