.reset-password-container {
  width: 90%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    //align-items: center;
    width: 100%;
    flex-grow: 1;

    .reset-button {
      display: flex;
      justify-content: center;

      button {
        background: var(--main-layout-text-color-2);
        color: white;
        text-transform: uppercase;
        font-weight: 400;
      }
    }
  }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';