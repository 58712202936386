@import "@styles/mixins.less";

.update-profile-modal-prototype {
  min-height: 100%;
  .ant-modal-content {
    justify-content: flex-start;
    max-height: 95%;
    overflow-y: scroll;
    width: 100%;
    padding: 3rem;
    .custom-scrollbar();
  }
  .ant-image {
    display: block;
    .logo {
      width: 6rem;
      margin: auto;
    }
  }
  .error-feedback-prototype {
    text-align: center;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';