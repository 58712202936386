.forget-password-modal-prototype {
  .forget-password-form {
    .ant-row {
      display: block;
    }
    .ant-input {
      font-size: 2.2rem;
    }
    .ant-form-item-label {
      label {
        color: #fff;
      }
    }
    .ant-input-suffix {
      display: none;
    }
    .input-forget-password {
      background-color: #fff;
      padding: 1rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';