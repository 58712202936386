.enter-balance-modal {
    .ant-modal-body {
        padding-bottom: 0;
        form {
            margin: auto 3.7rem auto 3.7rem;
        }
    }

    .ant-form-item-control-input {
        border-bottom: none;
    }

    .balance-box-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
    }

    .balance-input {
        font-size: 2rem;
        height: 4.8rem;
        margin-top: 1.6rem;
        background-color: #eeeeee;
    }
}

@media only screen and (max-width: 540px) {
    .enter-balance-modal {
        .balance-box-container {
            gap: 1rem;
        }

        .ant-modal-body {
            form {
                margin: auto 1.5rem auto 1.5rem;
            }
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';