@import "@styles/variables.less";
@import "@styles/mixins.less";

.rounded() {
  width: @sort-video-width;
  height: @sort-video-height;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.sorter-prototype {
  @gap-space: 1rem;

  background-color: @sorter-background;
  min-height: 84vh;
  padding: @gap-space;
  padding-right: 0;
  padding-bottom: 6rem;

  .sorter-row {
    position: relative;
    overflow-x: auto;
    max-width: 99vw;
    margin-top: @gap-space;
    scroll-behavior: smooth;

    &__content,
    & div {
      display: flex;
    }

    .sorter-title {
      height: auto;
      background-color: #081015;
      font-size: 11px;
      color: #ffffff66;
      text-transform: uppercase;
      font-family: sans-serif;
      padding: 0 10px;
      line-height: 20px;
      white-space: nowrap;
      border-radius: 4px;
      transition: color 0.2s ease-in-out;
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    &--dragging {
      box-shadow: 2px 2px 4px black;
      background-color: #0d1f29;
    }

    &__handle {
      width: 2.6rem;
      height: @sort-video-height;
      background: #0d1d24;
      background-image: url("../../../../assets/icons/handle.png");
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.7;
      cursor: ns-resize;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      margin-right: @gap-space;
      flex-shrink: 0;

      &:hover,
      &:active {
        background-color: #193e4c;
        opacity: 1;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .video-item {
      .rounded();
      cursor: move;
      position: relative;
      flex-shrink: 0;
      margin-right: @gap-space;

      &__label {
        background-color: rgba(0, 0, 0, 0.64);
        color: rgba(255, 255, 255, 0.7);
        font-family: sans-serif;
        font-size: 1rem;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
      }

      .home-position-small{
        width: 3.5rem;
        height: 3.5rem;
        padding: 0;
        margin: 0;
        z-index: 5;
        .ant-image{
          img{
            width: 3rem;
          }
        }
      }
    }
  }

  .add-new-video {
    .rounded();
    .flex-center();

    flex-direction: column;
    flex-shrink: 0;
    cursor: pointer;

    &.bottom {
      margin-top: 1.5rem;
    }

    img {
      width: 4rem;
      height: 4rem;
    }

    span {
      text-align: center;
    }

    color: rgba(255, 255, 255, 0.8);
    opacity: 0.3;
  }
}

@media only screen and (max-width: 1000px) {
  .sorter-prototype {
    min-height: 100vh;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';