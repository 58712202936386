.report-content-modal {
    top: 5%;
    .ant-modal-body {
        font-weight: 500;
    }

    .ant-radio-wrapper {
        color: #162d55;
    }
    .report-btn,
    .cancel-btn {
        min-width: 5rem;
        border: none;
        font-size: 1.6rem;
        display: inline;
        background-color: #fff;
        box-shadow: none;
        letter-spacing: 0.21px;
    }

    .report-reason {
        margin-top: 2.4rem;
        border-bottom: 2px solid #a8a8a8;

        &::placeholder {
            color: #a8a8a8;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a8a8a8;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #a8a8a8;
        }
    }

    .report-btn {
        color: #f08507;
    }
    .cancel-btn {
        color: #000;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';