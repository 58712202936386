@import "@styles/variables.less";

.scroll-top {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  border-radius: 50%;
  z-index: 100;

  cursor: pointer;

  width: 40px;
  height: 40px;

  animation: fade 0.3s ease;

  img {
    filter: invert(1);
    background-color: #eee;
    padding: 5px;
    border-radius: 50%;
  }
}

@media screen and (min-width: 1000px) {
  .scroll-top {
    margin-right: 5rem;
    transform: translateY(-5rem);
  }
}

@media screen and (min-width: 1400px) {
  .scroll-top {
    margin-right: 13rem;
    transform: translateY(-5rem);
  }
}

@media screen and (min-width: 1750px) {
  .scroll-top {
    margin-right: 22rem;
    transform: translateY(-5rem);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';