@import "@styles/mixins.less";

.search-result-list {
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  .custom-scrollbar();

  &.ant-list {
    .ant-spin-container {
      display: block;
    }
    .ant-list-item {
      width: 90%;
      margin: 1rem auto;
      display: grid;
      grid-template-columns: 5fr 1fr;
      .ant-typography {
        color: #fff;
        font-size: 1.8rem;
        margin-bottom: 0;
      }
      &:last-child {
        border-bottom: 1px #fff solid;
      }
    }
  }
  .item-img {
    width: 5rem;
  }

  .thunder-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-size: 30px 30px;
    position: relative;
    z-index: 10;
    background-color: #5e849d66;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';