.react-datepicker-with-input {
    position: absolute;
    margin-left: -8rem;
    z-index: 2;
    input {
        background-color: transparent;
        color: var(--main-layout-text-color);
        border: none;
        width: 8rem;
    }
}

.react-datepicker-with-input-popper {
    left: -8rem !important;
}

.react-datepicker {
    font-size: 1.4rem;
}
.react-datepicker__header {
    padding-top: 1rem;
}
.react-datepicker__month {
    margin: 0.6rem 1.2rem;
}
.react-datepicker__day-name,
.react-datepicker__day {
    width: 2.7rem;
    line-height: 2.5rem;
    margin: 0.4rem;
}
.react-datepicker__day--selected {
    background-color: var(--main-layout-text-color-2);
}
.react-datepicker__current-month {
    font-size: 1.6rem;
}
.react-datepicker__navigation {
    top: 0.6rem;
    line-height: 1.7rem;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1rem;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1rem;
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';