.video-player-wrapper {
  position: relative;
  width: 100%;
  height: 85vh;
  background-color: #000;
  z-index: 25;

  &.mobile {
    height: 91vh;
  }

  video {
    position: absolute;
  }
}

@media only screen and (min-height: 768px) {
  .video-player-wrapper {
    &.mobile {
      height: 98.5vh;
    }
  }
}
@media only screen and (min-height: 845px) {
  .video-player-wrapper {
    &.mobile {
      height: 100vh;
    }
  }
}
@media only screen and (max-height: 900px) {
  .video-player-wrapper {
    height: 82vh;
  }
}
@media only screen and (max-height: 768px) {
  .video-player-wrapper {
    height: 80vh;
  }
}
@media only screen and (max-height: 680px) {
  .video-player-wrapper {
    height: 78vh;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';