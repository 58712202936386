@import "@styles/variables.less";

.auth-showcase {
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        color: @light-orange;
        text-align: center;
        font-weight: normal;
    }

    .register-button {
        display: block;
        margin: 10rem auto 0 auto;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';