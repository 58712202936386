.search-radio-group-prototype {
  width: 100%;
  margin: auto;
  .ant-space-horizontal {
    width: 100%;
    justify-content: space-around;
    margin-top: 2rem;
  }
  .ant-radio-wrapper {
    span {
      color: #fff;
      font-size: 1.8rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';