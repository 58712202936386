.box {
    border: 1px solid #a8a8a8;
    width: 8.8rem;
    height: 4.8rem;
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
    color: #000000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 500px) {
    .box {
        width: 100%;
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 350px) {
    .box {
        font-size: 1.2rem;
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';