.setting-modal {
  .ant-modal-content {
    justify-content: center;
  }

}

.settings-prototype {
  text-align: center;
  height: 100%;
  justify-content: center;

  .auth-buttons-prototype {
    color: white;
    font-family: sans-serif;

    .auth-text {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      display: inline-block;
    }

    h1 {
      color: #fff;
    }

    h3 {
      font-weight: bold;
      color: inherit;
      font-size: 1.875rem;
    }

    button {
      background: transparent;
      width: 100%;
      text-transform: initial;
      color: inherit;
      font-size: 2rem;
      margin: 4px 0;
      font-weight: 100;
      padding: 2rem;
      height: auto;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.67);

      &:hover {
        width: 100%;
      }
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';