.profile-screen-prototype {
  background-color: rgba(11, 90, 142, 1);
  color: white;
  font-family: sans-serif;
  font-size: 2rem;
  display: flex;
  padding: 2rem 2rem 0 2rem;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  .image-container {
    margin-bottom: 1.5rem;
    .ant-image {
      display: block;
      margin: 0 auto;
      width: 8rem;
    }

    .profile-img {
      border-radius: 100rem;
    }
  }
  .btns-container {
    & > * {
      text-transform: uppercase;
    }
  }
  .profile-info-container {
    .ant-typography {
      margin-bottom: 1.5rem;
      color: white;
    }
  }
  .terms-links-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    a {
      color: white;
      font-size: 1.4rem;
      padding: 0 1rem;
    }
  }

  .close-btn {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    z-index: 10;
    padding: 10;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    width: 5rem;
    height: 5rem;
  }

  .close-icon {
    width: 2rem;
    color: white;
    font-weight: bold;
    font-size: 2.5rem;
  }

  .adult-content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
}


@media only screen and(max-height:850px) {
  .profile-screen-prototype {
    .terms-links-container {
      margin-bottom: 18rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';