.update-password-modal-prototype {
  .ant-modal-content {
    justify-content: flex-start;
  }
  .ant-image {
    display: block;
    .logo {
      width: 6rem;
      margin: auto;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';