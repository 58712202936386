.group-playlists {
  .ant-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 !important;
    align-items: center;
    .ant-form-item-control-input {
      border: none;
      width: 100%;
    }
    .ant-form-item {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .group-input {
      width: 100%;
      border: 0;
      border: 1px solid orange;
    }
  }
}
.ant-modal.modal-basic-prototype.alert-modal-prototype.group-playlists {
  width: 90% !important;
  max-width: 520px;
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';