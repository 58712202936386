@import "@styles/variables.less";

.ant-btn {
  border-radius: 3rem;
  text-transform: uppercase;
  min-width: 20rem;
  min-height: 4.8rem;
  font-size: 2rem;
  letter-spacing: @letter-spacing-big;

  &:hover {
    min-width: 26rem;
    transition: all 300ms;
  }
}

.btn-dark,
.btn-dark:visited,
.btn-dark:active {
  color: @primary-color;
  background: #ef85084f;
  border-color: @primary-color;
  &:hover,
  &:focus {
    background: @primary-color;
    color: #fff;
  }
}

.social-login-btn {
  position: relative;
  width: 14.7rem;
  height: 4rem;
  border-radius: 2.4rem;
  border: none;
  cursor: pointer;
  color: #fff;
  text-align: right;
  padding-right: 2rem;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.fb-login-btn {
    background-color: #3052a8;
  }

  &.google-login-btn {
    background-color: #dd4b39;
  }

  .ant-image {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);

    &::after {
      content: "";
      height: 4rem;
      width: 1px;
      background-color: var(--main-layout-text-color);
      position: absolute;
      left: 3.5rem;
      top: -0.8rem;
    }
  }
}

.logout-btn,
.login-btn {
  width: 17.4rem;
  min-width: 4rem;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: transparent;
  padding: 0.6rem 2.3rem 1rem 2.3rem;
  color: var(--main-layout-text-color-2);
  border-color: var(--main-layout-text-color-2);
  text-transform: capitalize;
  box-shadow: 0rem 0rem 0rem 0.1rem;

  &:disabled {
    background-color: transparent;
    color: var(--main-layout-text-color-2);
    border-color: var(--main-layout-text-color-2);
    opacity: 0.5;
  }

  .logout-image {
    min-width: 2rem;
  }
}

.ant-btn-prototype {
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background: #f08507ff;
  }
  &.white {
    background: white;
    border-color: white;

    &:hover,
    &:focus {
      background: white;
      border-color: white;
    }

    span {
      color: black;
    }
  }

  border-color: #f08507ff;
  color: white;
  background: #f08507ff;
  padding: 1.3rem;
  height: auto;
  width: 100%;
  font-size: 1.6rem;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 1rem;
  margin-bottom: 1rem !important;

  &:hover,
  &:focus {
    background: #f08507ff;
    color: white;
  }

  &.blue {
    background-color: #131a5e;
    border-color: #131a5e;
    margin: 0;

    &:hover {
      background-color: darken(#131a5e, 5%);
    }
    &:disabled {
      border-color: darken(#131a5e, 5%);
    }
  }
}

.response-buttons-prototype {
  margin: 0;
  min-width: 1rem;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  min-height: 1rem;
  &:hover,
  &:focus {
    min-width: 0;
  }

  &.blue {
    background-color: #1659ac;
    color: #fff;
  }
  &.gray {
    background-color: #d3d3d3;
    color: #000;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';