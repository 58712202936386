.playlist-selectable-item {
  display: grid;
  grid-template-columns: 1fr 80% 1fr;
  min-height: 5rem;
  margin: 0.5rem;

  .ant-checkbox-wrapper {
    align-items: center;
    justify-content: flex-end;
  }
  .playlist-image {
    width: 5rem;
    border-radius: 100rem;
    height: auto;
    background: #eee;
    border: 1px solid;
  }
  .playlist-name {
    padding-left: 1rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    max-width: fit-content;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';