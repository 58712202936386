.mobile-tiers{
  background-color: #173b50;
  .ant-card-body {
    padding: 12px;
    .infobox-prototype {
      margin: -2rem 0 1rem 0;
    }
  }
}
.channel-card {
  .ant-card-body {
    font-family: sans-serif;
    font-size: 1.4rem;
    color: white;
    background-color: #173b50;
    border-bottom: 1rem solid #091820;
    // max-height: 10rem;
  }
  .ant-image {
    width: 100%;

    .ant-image-img {
      display: inline-block;
      min-height: 28rem;
      max-height: 33rem;
      object-fit: cover;
    }
  }

  .infobox-prototype {
    transform: translateY(-4rem);
    z-index: 15;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';