.ant-modal {
  &.check-email-modal-prototype {
    top: 35%;
    max-width: 520px;
    width: auto !important;
    
    .ant-modal-content {
      border-radius: 2rem;
      width: 100%;
      .ant-modal-body {
        .ant-image {
          display: none;
          visibility: hidden;
        }

        border: 0;
      }

      .ant-modal-close {
        margin: rem;
      }
    }
    .modal-btn-prototype {
      width: 3rem;
    }
    .para {
      text-align: center;
      font-size: 2rem;
    
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';