.add-in-watch-later-container {
  margin-top: 5.5rem;
  .add-watch-later-container {
    display: grid;
    align-items: center;
    grid-template-columns: 5fr 1fr;
    column-gap: 2rem;
    padding: 1rem;
    margin: 0 0 1.5rem 0;

    input {
      color: #fff;
    }
  }

  .watch-later-btn {
    min-width: 15rem;
    margin-bottom: 0 !important;

    &:disabled {
      color: #fff;
      opacity: 0.5;
    }

    &:hover {
      min-width: 15rem;
      color: #000;
    }
  }

  .add-watch-later-container input {
    border: 1px solid #ef8508;
    padding: 10px 15px;
    font-size: 18px;
  }

  .add-watch-later-title {
    font-size: 23px;
    margin-top: 2rem;
    color: cornsilk;
    margin-left: 0.9rem;
  }

  &::placeholder {
    color: black;
  }
}

@media only screen and (max-width: 1000px) {
  .add-in-watch-later-container {
    .add-watch-later-title {
      padding-top: 0.8rem;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';