@import "@styles/variables.less";

.mobile-navigation-prototype {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: @nav-height;
  z-index: 20;
  background-color: #4a6d83;
  width: 100vw;

  .ant-list-item {
    padding: 0.5rem 0;
  }

  .mobile-navigation-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 5rem;
    max-height: 5rem;
    width: 100vw;
    z-index: 2555;

    &.team-wr {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .nav-item-prototype {
    padding-top: 0.5rem;
    position: relative;
    text-align: center;
    bottom: 0.46rem;
  }

  .ant-list-item {
    display: block;
    height: 100%;
  }

  .tv-image {
    width: 3.2rem;
    height: 3.6rem;
    padding-top: 0.4rem;
  }
  .item-padd {
    padding-top: 0.75rem;
  }
  .my-playlist-icon {
    width: 4.2rem;
    height: 4.2rem;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-navigation-prototype {
    height: 6.5rem;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';