.subbar-item-container {
    &.active {
        background-color: #577d97;
        border-radius: 1rem;
        padding: 1rem 0.2rem;
    }

    .subbar-svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';