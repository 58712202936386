.popup-item {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    column-gap: 2rem;
    padding: 1rem;

    &:hover {
        background-color: #485d81;
    }
}

@media only screen and (max-width: 1300px) {
    .popup-item {
        column-gap: 1rem;
        padding: 0.8rem;

        svg{
            min-width: 2rem;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';