.search-modal-prototype-wrap {
  &.height-21 {
    max-height: calc(100vh - 21rem);
  }
  &.height-15 {
    max-height: calc(100vh - 15rem);
  }
}
.search-modal-prototype {
  &.height-25 {
    max-height: calc(100vh - 25rem);
  }
  &.height-15 {
    max-height: calc(100vh - 15rem);
  }

  .modal-title {
    color: white;
    text-align: center;
    font-weight: normal;
    font-family: sans-serif;
    font-size: 3.4rem;
  }

  .ant-modal-content {
    display: block;
  }
  .search-input {
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
    font-size: 30px;
    width: 95%;
    display: block;
    margin: 0 auto;
  }
  .ant-modal-body {
    margin-top: 5rem;
  }
}

@media only screen and (max-height: 600px), screen and (max-width: 800px) {
  .search-modal-prototype-wrap {
    &.height-21 {
      max-height: calc(100vh - 15rem);
    }
  }
  .search-modal-prototype {
    height: 100%;
    &.height-25 {
      max-height: calc(100vh - 21rem);
    }
    &.height-15 {
      max-height: calc(100vh - 21rem);
    }
  }
}

@media only screen and (max-height: 680px) {
  .search-modal-prototype {
    padding-bottom: 0;
    .search-input {
      height: 5rem;
    }
    .ant-modal-body {
      overflow-y: scroll;
      height: 65vh;
      margin-top: 1.5rem;
    }
  }
}
@media only screen and (max-height: 400px) {
  .search-modal-prototype {
    .ant-modal-body {
      height: 55vh;
    }
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';