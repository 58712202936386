.nav-item {
    position: relative;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 1rem;
    background-color: darken(#1d3c71, 4%);

    &:hover {
        background-color: #1d3c71;
        cursor: pointer;
        transition: all 0.3s;
    }

    &:not(:hover) {
        transition: all 0.3s;
    }

    .nav-icon {
        width: 3.2rem;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.mobile-navigation {
    .active-nav-item {
        .nav-item {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                bottom: -1rem;
                left: 0;
                width: 100%;
                background-color: var(--main-layout-text-color-2);
                height: 5px;
                border-radius: 5px;
            }
        }
    }
    .nav-item {
        background-color: transparent;
        border-radius: 0;
        flex: 1;
        // padding: 3rem;
    }
}


//prototype styles

// .nav-item-
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';