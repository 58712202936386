@import "@styles/variables.less";

.auth-form {
    max-width: 50%;
    margin: auto;
    .ant-form {
        .register-btn-container,
        .done-btn-container {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
        }
    }

    &-mobile {
        width: 90%;
        margin: 4.2rem auto;
        margin-bottom: 0;

        .back-to-login {
            color: @primary-color;
        }

        input::placeholder {
            font-size: 1.6rem;
            padding-bottom: 3rem;
        }
    }
    label {
        color: @text-color-dark;
        font-size: 2rem;
        padding-left: 1rem;
    }

    .login-btn-container {
        &-mobile {
            margin-bottom: 2.5rem;
        }
        margin: 4.4rem auto auto auto;
        margin-bottom: 2rem;
        button {
            box-shadow: @light-box-shadow;
        }
    }

    .login-btn-auth {
        display: block;
        margin: 0 auto;
    }
    .login-btn-mobile {
        width: 90%;
    }

    .link-register-content-mobile {
        text-align: center;
        color: @text-color-dark;

        & a {
            color: @text-color-dark;
            font-weight: bold;
        }
    }

    .forgot-password {
        cursor: pointer;
        display: block;
        text-align: right;
        color: @text-color-dark;
        font-weight: 500;
        margin-top: 0.5rem;
        font-size: 1.6rem;

        &-mobile {
            margin-bottom: 2rem;
            font-size: 1.2rem;
        }
    }
    .re-captcha {
        transform: scale(0.8);
        position: relative;
        left: -2rem;
        div:first-of-type {
            margin: auto;
        }
    }
    .re-captcha-mobile {
        div:first-of-type {
            margin: auto;
        }
    }
}

@media (max-width: 1700px) {
    .auth-form {
        .ant-form {
            .ant-form-item {
                margin-bottom: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .auth-form {
        max-width: 70%;
    }
}
@media only screen and (max-width: 992px) {
    .auth-form {
        max-width: 90%;
    }
}

@media only screen and (max-height: 700px) {
    .auth-form .login-btn-container {
        margin: 1rem auto 1rem auto;
    }
}

@media only screen and (max-height: 610px) {
    .auth-form {
        .login-btn-container {
            margin: 1rem auto 1rem auto;
        }

        .forgot-password {
            margin-top: 0;
            font-size: 1.2rem;
        }
    }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';