@import "@styles/mixins.less";

.add-member-modal {
  top: 35%;

  .custom-scrollbar();
  .ant-modal-content,
  .ant-modal-header {
    color: rgba(11, 90, 142);
  }

  .ant-modal-body {
    max-height: 60vh;
    overflow-y: scroll;
    .custom-scrollbar();
  }

  .confirm-btn {
    justify-content: center;
    background: #ef8508;
    outline: none;
    border-radius: 10px;
    border: none;
    padding: 5px 15px;
    color: white;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    min-width: 120px;
  }

  .members-input {
    width: 100%;
    border-radius: 1rem;
    color:#000;
    border: 1px solid #ef8508;
    margin-top: 20px;
    padding: 6px 12px;
    outline: none;
  }

  .members-input::placeholder {
    opacity: .5;
  }
}


@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';