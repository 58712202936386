@import "@styles/mixins.less";
@import "@styles/variables.less";

.cancel-arrange-mode {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  p {
    width: fit-content;
    padding: 2px 0;
    margin: 0;
    border-radius: 1rem;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    font-weight: 400;
  }

  button {
    background: none;
    outline: none;
    border: none;
    color: white;
    border: 1px solid #333;
    background-color: #333;
    border-radius: 1rem;
    padding: 5px;
    cursor: pointer;
    margin-left: 1rem;
  }
}

.sub-bar-prototype {
  position: fixed;

  left: 0;
  right: 0;
  bottom: 4.8rem;
  overflow-y: hidden;
  // overflow-x: hidden;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  transition: bottom 0.5s ease-in-out;
  z-index: 17;

  &__content {
    background-color: rgba(46, 72, 89, 1);
  }

  .scroll-cont {
    overflow-y: hidden;
    &.indiana-scroll-container--hide-scrollbars {
      overflow: scroll;
    }
    .custom-scrollbar();
  }

  .ant-spin-container {
    // overflow: hidden;
    // overflow-x: scroll;
    align-items: center;

    .custom-scrollbar();
  }
}

@keyframes subBarUp {
  0% {
    transform: translateY(10rem);
    opacity: 0;
  }
  70% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
@keyframes subBarDown {
  0% {
    transform: translateY(0rem);
    opacity: 1;
  }
  100% {
    transform: translateY(10rem);
    opacity: 0.2;
  }
}

@media only screen and (max-width: 768px) {
  .sub-bar-prototype {
    bottom: 6rem;
  }
}

@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';