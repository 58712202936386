.modal-basic-prototype {

    .modal-button {
        margin-top: 10px;
        width: 100%;
    }

    &.edit-team-member-popup {
        padding: 20px;
    }
 }

 .ant-modal-footer {
     .edit-team-member-modal-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        button {
            background-color: #f08605;
            color: #fff;
            border-radius: 8px;
            border: none;
            padding: 0.7rem 1rem;
            width: 100%;
            margin-top: 1rem;
        }

        .permission-text {
            color: #f08605;
            font-size: 18px;
        }
    }
 }
@primary-color: #EF8508;@layout-body-background: #FFFFFF;@grid-columns: 40;@text-color-dark: #162D55;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';